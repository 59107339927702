import useGetMarketVestingsFromBarn from 'hooks/barn/evm/useGetMarketVestingsFromBarn'
import useGetTokenLargestHolders from 'hooks/barn/evm/useGetTokenLargestHolders'
import useGetTopStakingPositions from 'hooks/barn/evm/useGetTopStakingPositions'
import { useMemo } from 'react'
import { Chain } from 'types/dexbarn'
import { zeroAddress } from 'viem'

interface UseGetTopHoldersProps {
  baseTokenAddress: string
  chain: Chain
  enabled: boolean
  marketAddress: string
}

const useGetTopHolders = ({
  baseTokenAddress,
  chain,
  enabled,
  marketAddress
}: UseGetTopHoldersProps) => {
  const { data: topHolders, isLoading: isLoadingTopHolders } =
    useGetTokenLargestHolders({
      chain,
      enabled,
      tokenAddress: baseTokenAddress
    })

  const { data: stakingPositions, isLoading: isLoadingStakingPositions } =
    useGetTopStakingPositions({ chain, enabled, marketAddress })

  const { data: vestings, isLoading: isLoadingVestings } =
    useGetMarketVestingsFromBarn({
      chain,
      enabled,
      marketAddress
    })

  const data = useMemo(() => {
    const holdersMap = new Map<
      string,
      {
        balance: number
        isMarket: boolean
        staked: number
        total: number
        userAddress: string
        vesting: number
      }
    >()

    // Add token holders
    topHolders
      ?.filter(
        (holder) =>
          holder.userAddress !== zeroAddress &&
          holder.userAddress !== marketAddress
      )
      .forEach((holder) => {
        holdersMap.set(holder.userAddress, {
          balance: Number(holder.balance),
          isMarket: false,
          staked: 0,
          total: Number(holder.balance),
          userAddress: holder.userAddress,
          vesting: 0
        })
      })

    // Add/merge staking positions
    stakingPositions?.users.forEach((position) => {
      const existing = holdersMap.get(position.user)
      const amountStakedNum = Number(position.amountStaked)
      if (existing) {
        existing.staked = amountStakedNum
        existing.total = existing.balance + amountStakedNum
      } else {
        holdersMap.set(position.user, {
          balance: 0,
          isMarket: false,
          staked: amountStakedNum,
          total: amountStakedNum,
          userAddress: position.user,
          vesting: 0
        })
      }
    })

    // Add vesting
    vestings?.pages.forEach((schedules) => {
      schedules.forEach((schedule) => {
        const existing = holdersMap.get(schedule.beneficiary)
        const vesting =
          Number(schedule.total.formatted) - Number(schedule.released.formatted)
        if (existing) {
          existing.total = existing.total + vesting
          existing.vesting = vesting
        } else {
          holdersMap.set(schedule.beneficiary, {
            balance: 0,
            isMarket: false,
            staked: 0,
            total: vesting,
            userAddress: schedule.beneficiary,
            vesting
          })
        }
      })
    })

    return Array.from(holdersMap.values())
      .sort((a, b) => b.total - a.total)
      .filter((holder) => holder.total > 0 && !holder.isMarket)
      .slice(0, 10)
  }, [topHolders, stakingPositions, vestings, marketAddress])

  return {
    data,
    isLoading:
      isLoadingStakingPositions || isLoadingTopHolders || isLoadingVestings
  }
}

export default useGetTopHolders
