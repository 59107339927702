import { Box, BoxProps, useColorMode } from '@chakra-ui/react'
import { CNATIVE } from 'constants/token'
import {
  createChart,
  CrosshairMode,
  IChartApi,
  LineData,
  LineStyle,
  MouseEventParams,
  SeriesMarker,
  UTCTimestamp
} from 'lightweight-charts'
import React, { useEffect, useRef } from 'react'
import { Chain } from 'types/dexbarn'
import {
  calculateQuoteAmountToBuySupply,
  fillDataWithWhitespace,
  PricePoint
} from 'utils/bondingCurves'
import { getChainId } from 'utils/chains'
import { formattedNum } from 'utils/format'

interface BondingCurvesChartProps {
  askPricePoints: PricePoint[]
  bidPricePoints: PricePoint[]
  chain: Chain
  adjustVisibleRangeOnLoad?: boolean
  askPrice?: number
  bidPrice?: number
  circulatingSupply?: number
  containerProps?: BoxProps
}

const BondingCurvesChart = ({
  adjustVisibleRangeOnLoad,
  askPrice,
  askPricePoints,
  bidPrice,
  bidPricePoints,
  chain,
  circulatingSupply,
  containerProps
}: BondingCurvesChartProps) => {
  const chainId = getChainId(chain)
  const nativeCurrency = CNATIVE[chainId]

  const { colorMode } = useColorMode()
  const borderColor = colorMode === 'light' ? '#ececfe' : '#2f3146'
  const textSecondaryColor = colorMode === 'light' ? '#8c8cb1' : '#9295bf'

  const chartContainerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<IChartApi | null>(null)

  useEffect(() => {
    if (!chartContainerRef.current) return

    const chart = createChart(chartContainerRef.current, {
      crosshair: {
        horzLine: {
          labelVisible: false
        },
        mode: CrosshairMode.Magnet,
        vertLine: {
          labelVisible: false
        }
      },
      grid: {
        horzLines: {
          color: borderColor,
          style: LineStyle.Dashed
        },
        vertLines: {
          color: borderColor,
          style: LineStyle.Dashed
        }
      },
      layout: {
        background: {
          color: 'transparent'
        },
        textColor: textSecondaryColor
      },
      leftPriceScale: {
        visible: false
      },
      rightPriceScale: {
        borderColor: borderColor,
        minimumWidth: 20,
        scaleMargins: {
          bottom: 0,
          top: 0.05
        },
        visible: true
      },
      timeScale: {
        borderColor,
        fixLeftEdge: false,
        fixRightEdge: false,
        minBarSpacing: 0,
        tickMarkFormatter: (time: number) => {
          return formattedNum(time, { places: 2 })
        },
        tickMarkMaxCharacterLength: 14
      },
      width: chartContainerRef.current.clientWidth
    })

    chartRef.current = chart

    const askLineSeries = chart.addLineSeries({
      color: 'rgba(253, 73, 60, 0.3)',
      lastValueVisible: false,
      lineWidth: 2,
      priceFormat: {
        formatter: (price: number) => {
          return formattedNum(price, {
            allowSmallDecimals: true
          })
        },
        minMove: 0.00001,
        type: 'custom'
      },
      priceLineVisible: false
    })

    const bidLineSeries = chart.addLineSeries({
      color: 'rgba(0, 200, 83, 0.3)',
      lastValueVisible: false,
      lineWidth: 2,
      priceFormat: {
        formatter: (price: number) => {
          return formattedNum(price, {
            allowSmallDecimals: true
          })
        },
        minMove: 0.00001,
        type: 'custom'
      },
      priceLineVisible: false
    })

    const askAreaSeries = chart.addAreaSeries({
      bottomColor: 'rgba(253, 73, 60, 0.1)',
      lastValueVisible: true,
      lineColor: 'rgba(253, 73, 60, 1)',
      lineWidth: 2,
      priceLineVisible: true,
      topColor: 'rgba(253, 73, 60, 0.4)'
    })

    const bidAreaSeries = chart.addAreaSeries({
      bottomColor: 'rgba(0, 200, 83, 0.1)',
      lastValueVisible: true,
      lineColor: 'rgba(0, 200, 83, 1)',
      lineWidth: 2,
      priceLineVisible: true,
      topColor: 'rgba(0, 200, 83, 0.4)'
    })

    const MAX_POINTS = 1000
    const filledAskData = circulatingSupply
      ? fillDataWithWhitespace(
          askPricePoints,
          askPrice,
          circulatingSupply,
          MAX_POINTS
        )
      : askPricePoints
    const filledBidData = circulatingSupply
      ? fillDataWithWhitespace(
          bidPricePoints,
          bidPrice,
          circulatingSupply,
          MAX_POINTS
        )
      : bidPricePoints

    const askCustomData = filledAskData.map((point) => ({
      time: point.supply as unknown as UTCTimestamp,
      value: point.price
    })) as LineData[]

    const bidCustomData = filledBidData.map((point) => ({
      time: point.supply as unknown as UTCTimestamp,
      value: point.price
    })) as LineData[]

    const askAreaData = circulatingSupply
      ? askCustomData.filter(
          (point) => (point.time as number) <= circulatingSupply
        )
      : []

    const bidAreaData = circulatingSupply
      ? bidCustomData.filter(
          (point) => (point.time as number) <= circulatingSupply
        )
      : []

    askLineSeries.setData(askCustomData)
    bidLineSeries.setData(bidCustomData)
    askAreaSeries.setData(askAreaData)
    bidAreaSeries.setData(bidAreaData)

    // Add markers for original price points
    const askMarkers: SeriesMarker<UTCTimestamp>[] = askPricePoints.map(
      (point) => ({
        color: 'rgba(253, 73, 60, 1)',
        position: 'inBar',
        shape: 'circle',
        size: 0.1,
        time: point.supply as unknown as UTCTimestamp
      })
    )

    const bidMarkers: SeriesMarker<UTCTimestamp>[] = bidPricePoints.map(
      (point) => ({
        color: 'rgba(0, 200, 83, 1)',
        position: 'inBar',
        shape: 'circle',
        size: 0.1,
        time: point.supply as unknown as UTCTimestamp
      })
    )

    askLineSeries.setMarkers(askMarkers)
    bidLineSeries.setMarkers(bidMarkers)

    const tooltip = document.createElement('div')
    tooltip.style.cssText = `
      position: absolute;
      display: none;
      padding: 8px;
      box-sizing: border-box;
      font-size: 12px;
      text-align: left;
      z-index: 1000;
      pointer-events: none;
      border-radius: 0px;
      background: ${colorMode === 'light' ? 'white' : '#1A202C'};
      color: ${colorMode === 'light' ? 'black' : 'white'};
      border: 1px solid ${borderColor};
      min-width: 150px;
    `
    chartContainerRef.current.appendChild(tooltip)

    chart.subscribeCrosshairMove((param: MouseEventParams) => {
      if (
        param.point === undefined ||
        param.time === undefined ||
        param.point.x < 0 ||
        param.point.y < 0
      ) {
        tooltip.style.display = 'none'
        return
      }

      const supply = param.time as number
      const askDataPoint = filledAskData.find(
        (point) => point.supply === supply
      )
      const bidDataPoint = filledBidData.find(
        (point) => point.supply === supply
      )

      if (askDataPoint && bidDataPoint) {
        const quoteAmountToBuySupply = calculateQuoteAmountToBuySupply(
          askDataPoint.supply,
          askPricePoints
        )

        // Update tooltip content and position
        tooltip.style.display = 'block'
        tooltip.innerHTML = `
          <div style="margin-bottom: 4px">Supply: ${formattedNum(supply, {
            places: 2
          })}</div>
          <div style="margin-bottom: 4px">Cost: ${formattedNum(
            quoteAmountToBuySupply,
            { places: 2 }
          )} ${nativeCurrency.symbol}</div>
          <div style="margin-bottom: 4px">Buy: ${formattedNum(
            askDataPoint.price,
            { allowSmallDecimals: true }
          )} ${nativeCurrency.symbol}</div>
          <div>Sell: ${formattedNum(bidDataPoint.price, {
            allowSmallDecimals: true
          })} ${nativeCurrency.symbol}</div>
        `

        // Position tooltip
        const chartRect = chartContainerRef.current!.getBoundingClientRect()
        const tooltipWidth = tooltip.offsetWidth
        const tooltipHeight = tooltip.offsetHeight

        let left = param.point.x + 20
        let top = param.point.y + 20

        // Adjust if tooltip would overflow right edge
        if (left + tooltipWidth > chartRect.width) {
          left = param.point.x - tooltipWidth - 20
        }

        // Adjust if tooltip would overflow bottom edge
        if (top + tooltipHeight > chartRect.height) {
          top = param.point.y - tooltipHeight - 20
        }

        tooltip.style.left = `${left}px`
        tooltip.style.top = `${top}px`
      }
    })

    if (askPricePoints.length > 0) {
      chart.timeScale().setVisibleRange({
        from: 0 as unknown as UTCTimestamp,
        to: Math.max(
          ...askPricePoints.map((point) => point.supply)
        ) as unknown as UTCTimestamp
      })
    }

    const handleResize = () => {
      if (chartContainerRef.current) {
        chart.resize(chartContainerRef.current.clientWidth, 400)
      }
    }

    window.addEventListener('resize', handleResize)

    if (adjustVisibleRangeOnLoad) {
      requestAnimationFrame(() => {
        const visibleLogicalRange = chart.timeScale().getVisibleLogicalRange()
        if (visibleLogicalRange !== null) {
          chart.timeScale().setVisibleLogicalRange({
            from: -50,
            to: visibleLogicalRange.to + 50
          })
        }
      })
    }

    // Add this code after chart creation
    const logoElement = document.querySelector('#tv-attr-logo')
    if (logoElement) {
      ;(logoElement as HTMLElement).style.display = 'none'
    }

    return () => {
      window.removeEventListener('resize', handleResize)
      if (tooltip) {
        tooltip.remove()
      }
      chart.remove()
    }
  }, [
    askPricePoints,
    bidPricePoints,
    chain,
    circulatingSupply,
    borderColor,
    textSecondaryColor,
    askPrice,
    bidPrice,
    colorMode,
    nativeCurrency.symbol,
    adjustVisibleRangeOnLoad
  ])

  return (
    <Box
      ref={chartContainerRef}
      height="400px"
      pos="relative"
      w="full"
      {...containerProps}
    />
  )
}

export default BondingCurvesChart
