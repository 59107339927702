import { TokenMillLensAbi } from 'constants/abi/tokenMillLens'
import { TM_LENS } from 'constants/addresses'
import { UseGetStakePositionProps } from 'hooks/tokenmill/useGetStakePosition'
import { UserStakePosition } from 'types/market'
import { getChainId } from 'utils/chains'
import { formatUnits, getAddress, isAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

const useGetStakePosition = ({
  baseTokenAddress,
  chain
}: UseGetStakePositionProps) => {
  const { address: account } = useAccount()
  const chainId = getChainId(chain)

  return useReadContract({
    abi: TokenMillLensAbi,
    address: TM_LENS[chainId],
    args:
      account && baseTokenAddress && isAddress(baseTokenAddress)
        ? [account, getAddress(baseTokenAddress), BigInt(0), BigInt(50)]
        : undefined,
    chainId,
    functionName: 'getSingleDetailedStakingDataPerUser',
    query: {
      select: (data) => {
        return {
          amountStaked: {
            formatted: formatUnits(data.sharesAmount, 18),
            value: data.sharesAmount
          },
          pendingRewards: {
            formatted: formatUnits(data.pendingRewards, 18),
            value: data.pendingRewards
          }
        } satisfies UserStakePosition
      }
    }
  })
}

export default useGetStakePosition
