import {
  Box,
  Center,
  HStack,
  IconButton,
  keyframes,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { PauseIcon, PlayIcon } from 'theme/icons'

const pulseKeyframes = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7); }
  70% { box-shadow: 0 0 0 10px rgba(0, 255, 0, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 255, 0, 0); }
`

interface LiveIndicatorProps {
  isPaused: boolean
  onTogglePause: () => void
  pausedDataCount: number
}

const LiveIndicator: React.FC<LiveIndicatorProps> = ({
  isPaused,
  onTogglePause,
  pausedDataCount
}) => {
  const pulseAnimation = `${pulseKeyframes} 2s infinite`

  return (
    <HStack spacing={3}>
      <Box
        boxSize={2.5}
        borderRadius="50%"
        backgroundColor={isPaused ? 'orange.400' : 'green.400'}
        animation={isPaused ? 'none' : pulseAnimation}
        transition="all 0.3s ease"
      />
      <Text fontSize="sm" textColor={isPaused ? 'textSecondary' : 'green.400'}>
        {isPaused ? 'Paused' : 'Live'}
      </Text>
      <Box position="relative">
        <IconButton
          variant="boxShadowFlat"
          aria-label={isPaused ? 'Pause live feed' : 'Start live feed'}
          bg="bgSecondary"
          size="sm"
          onClick={onTogglePause}
          icon={isPaused ? <PlayIcon /> : <PauseIcon />}
        />
        {isPaused && pausedDataCount > 0 && (
          <Center
            bg="red.500"
            minW="18px"
            position="absolute"
            top="-8px"
            right="-8px"
            borderRadius="full"
            fontSize="xs"
            textColor="white"
          >
            {pausedDataCount}
          </Center>
        )}
      </Box>
    </HStack>
  )
}

export default LiveIndicator
