import { TokenMillLensAbi } from 'constants/abi/tokenMillLens'
import { TM_LENS } from 'constants/addresses'
import { UseGetMarketStakingDataProps } from 'hooks/tokenmill/useGetMarketStakingData'
import { MarketStakingData } from 'types/market'
import { getChainId } from 'utils/chains'
import { formatUnits, getAddress, isAddress } from 'viem'
import { useReadContract } from 'wagmi'

const useGetMarketStakingData = ({
  baseTokenAddress,
  chain
}: UseGetMarketStakingDataProps) => {
  const chainId = getChainId(chain)

  return useReadContract({
    abi: TokenMillLensAbi,
    address: TM_LENS[chainId],
    args:
      baseTokenAddress && isAddress(baseTokenAddress)
        ? [getAddress(baseTokenAddress)]
        : undefined,
    chainId,
    functionName: 'getSingleDetailedTokenStakingData',
    query: {
      enabled: !!baseTokenAddress,
      select: (data) => {
        return {
          totalLockedAmount: {
            formatted: formatUnits(data.totalLocked, 18),
            value: data.totalLocked
          },
          totalStakedAmount: {
            formatted: formatUnits(data.totalStaked, 18),
            value: data.totalStaked
          },
          totalStakedAndLockedAmount: {
            formatted: formatUnits(data.totalStaked + data.totalLocked, 18),
            value: data.totalStaked + data.totalLocked
          }
        } satisfies MarketStakingData
      }
    }
  })
}

export default useGetMarketStakingData
