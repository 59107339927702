import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { SolbarnStakingPositions } from 'types/solbarn'

const useGetTopStakingPositions = ({
  enabled,
  marketAddress
}: {
  enabled: boolean
  marketAddress: string
}) => {
  const fetchStakingPositions = useSolbarnGet<SolbarnStakingPositions>(
    `/v1/staking/market/${marketAddress}`
  )

  return useQuery<SolbarnStakingPositions>({
    enabled: !!marketAddress && enabled,
    queryFn: fetchStakingPositions,
    queryKey: ['stakingPositions', marketAddress]
  })
}

export default useGetTopStakingPositions
