import {
  Button,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import React from 'react'
import { Chain } from 'types/dexbarn'

import { CreateTokenStatus } from './index'

interface TokenCreationStatusModalProps {
  baseTokenSymbol: string
  chain: Chain
  createTokenStatus: CreateTokenStatus
  marketAddress: string
  onRetry: () => void
}

const TokenCreationStatusModal = ({
  baseTokenSymbol,
  chain,
  createTokenStatus,
  marketAddress,
  onRetry
}: TokenCreationStatusModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  React.useEffect(() => {
    if (createTokenStatus !== 'idle') {
      onOpen()
    }
  }, [createTokenStatus, onOpen])

  const statusItems = [
    {
      isFailed: createTokenStatus === 'error_create_market',
      isLoading: createTokenStatus === 'create_market',
      isSuccessful:
        createTokenStatus !== 'create_market' &&
        createTokenStatus !== 'error_create_market',
      label: 'Create Market and Token'
    },
    {
      isFailed: createTokenStatus === 'error_patch_market',
      isLoading: createTokenStatus === 'patch_market',
      isSuccessful: createTokenStatus === 'done',
      label: 'Waiting for token to be available on TokenMill'
    }
  ]

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Create {baseTokenSymbol} market</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {statusItems.map((item, index) => (
              <StatusItem key={index} index={index} {...item} />
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          {createTokenStatus === 'error_create_market' ||
          createTokenStatus === 'error_patch_market' ? (
            <Button variant="boxShadowFlat" bg="accent.500" onClick={onRetry}>
              Try Again
            </Button>
          ) : (
            <Link href={`/${chain}/${marketAddress}`} color="accent.500">
              <Button
                variant="boxShadowFlat"
                bg="accent.500"
                isLoading={
                  createTokenStatus === 'create_market' ||
                  createTokenStatus === 'patch_market'
                }
                loadingText="Waiting for confirmation"
              >
                View Token Page
              </Button>
            </Link>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const StatusItem = ({
  index,
  isFailed,
  isLoading,
  isSuccessful,
  label
}: {
  index: number
  isFailed: boolean
  isLoading: boolean
  isSuccessful: boolean
  label: string
}) => (
  <HStack align="start" spacing={2} justify="space-between">
    <Text fontWeight="bold">{`${index + 1}. ${label}`}</Text>
    {isLoading ? (
      <Spinner size="sm" />
    ) : isSuccessful ? (
      <Text fontWeight="semibold" color="green.400">
        Completed
      </Text>
    ) : isFailed ? (
      <Text fontWeight="semibold" color="red.500">
        Failed
      </Text>
    ) : null}
  </HStack>
)

export default TokenCreationStatusModal
