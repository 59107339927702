import { createReducer } from '@reduxjs/toolkit'

import {
  setAreTermsAccepted,
  setChainView,
  setFeeMode,
  setIsHeaderCollapsed,
  setIsUKDisclaimerHidden,
  setMaxTransactionFee,
  setOriginCountryCode
} from './actions'

export const DEFAULT_MAX_TRANSACTION_FEE = '0.005'
export const DEFAULT_FEE_MODE = 'maxCap'

export type FeeMode = 'maxCap' | 'exactFee'

export type ChainView = 'sol' | 'avax' | 'split'

export const DEFAULT_CHAIN_VIEW: ChainView = 'split'

interface SettingsState {
  readonly areTermsAccepted?: boolean
  readonly chainView?: ChainView
  readonly feeMode?: FeeMode
  readonly isHeaderCollapsed?: boolean
  readonly isUKDisclaimerHidden?: boolean
  readonly maxTransactionFee?: string
  readonly originCountryCode?: string
}

const initialState: SettingsState = {
  areTermsAccepted: false,
  chainView: DEFAULT_CHAIN_VIEW,
  feeMode: DEFAULT_FEE_MODE,
  isHeaderCollapsed: false,
  maxTransactionFee: DEFAULT_MAX_TRANSACTION_FEE
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setAreTermsAccepted, (state, action) => {
      state.areTermsAccepted = action.payload
    })

    .addCase(setOriginCountryCode, (state, action) => {
      state.originCountryCode = action.payload
    })
    .addCase(setIsUKDisclaimerHidden, (state, action) => {
      state.isUKDisclaimerHidden = action.payload
    })
    .addCase(setMaxTransactionFee, (state, action) => {
      state.maxTransactionFee = action.payload
    })
    .addCase(setFeeMode, (state, action) => {
      state.feeMode = action.payload
    })
    .addCase(setIsHeaderCollapsed, (state, action) => {
      state.isHeaderCollapsed = action.payload
    })
    .addCase(setChainView, (state, action) => {
      state.chainView = action.payload
    })
)
