import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet, useSolbarnGet } from 'hooks/useDexbarn'
import { useReferralStorage } from 'state/referral'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

const useGetReferrerAddressOnChain = ({
  chain,
  enabled
}: {
  chain: Chain
  enabled: boolean
}) => {
  const chainType = getChainType(chain)
  const { referralState } = useReferralStorage()
  const referralCode = referralState?.[chainType].code

  const fetchReferrerAddressEVM = useDexbarnGet<string>(
    `/v1/users/referrer-address/${referralCode}`
  )

  const fetchReferrerAddressSolana = useSolbarnGet<string>(
    `/v1/users/referrer-address/${referralCode}`
  )

  return useQuery({
    enabled: enabled && !!referralCode,
    queryFn: () => {
      switch (chain) {
        case 'avalanche':
          return fetchReferrerAddressEVM()
        case 'solana':
          return fetchReferrerAddressSolana()
      }
    },
    queryKey: ['getReferrerAddress', chain, referralCode]
  })
}

export default useGetReferrerAddressOnChain
