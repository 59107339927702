import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  VStack
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { SocialUrls, validateSocialUrl } from 'utils/fields'

interface CreateTokenSocialUrlInputsProps {
  isExpandable: boolean
  setSocialUrls: React.Dispatch<React.SetStateAction<SocialUrls>>
  socialUrls: SocialUrls
}

const CreateTokenSocialUrlInputs: React.FC<CreateTokenSocialUrlInputsProps> = ({
  isExpandable,
  setSocialUrls,
  socialUrls
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [errors, setErrors] = useState<Partial<SocialUrls>>({})

  const handleInputChange =
    (field: keyof SocialUrls) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation()

      const value = e.target.value
      const error = validateSocialUrl(value, field)

      setSocialUrls((prev) => ({ ...prev, [field]: value }))
      setErrors((prev) => ({ ...prev, [field]: error }))
    }

  return (
    <Box w="full">
      {isExpandable ? (
        <Flex justifyContent="center">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="ghost">
            {isExpanded ? 'Show Less' : 'Show More'}
          </Button>
        </Flex>
      ) : null}
      <Collapse in={isExpanded || !isExpandable} animateOpacity>
        <VStack spacing={4} align="stretch" mx="1px" mb="1px">
          <FormControl isInvalid={!!errors.twitter}>
            <Text textColor="textPrimary" fontSize="sm" mb={1}>
              X URL
            </Text>
            <Input
              placeholder="https://x.com/..."
              value={socialUrls.twitter}
              onChange={handleInputChange('twitter')}
            />
            <FormErrorMessage>{errors.twitter}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.discord}>
            <Text textColor="textPrimary" fontSize="sm" mb={1}>
              Discord URL
            </Text>
            <Input
              placeholder="https://discord.gg/..."
              value={socialUrls.discord}
              onChange={handleInputChange('discord')}
            />
            <FormErrorMessage>{errors.discord}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.website}>
            <Text textColor="textPrimary" fontSize="sm" mb={1}>
              Website URL
            </Text>
            <Input
              placeholder="https://..."
              value={socialUrls.website}
              onChange={handleInputChange('website')}
            />
            <FormErrorMessage>{errors.website}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.telegram}>
            <Text textColor="textPrimary" fontSize="sm" mb={1}>
              Telegram URL
            </Text>
            <Input
              placeholder="https://t.me/..."
              value={socialUrls.telegram}
              onChange={handleInputChange('telegram')}
            />
            <FormErrorMessage>{errors.telegram}</FormErrorMessage>
          </FormControl>
        </VStack>
      </Collapse>
    </Box>
  )
}

export default CreateTokenSocialUrlInputs
