import AvalancheIcon from 'assets/avalanche.svg'
import { Chain as DexbarnChain } from 'types/dexbarn'
import {
  avalanche as wagmiAvalanche,
  avalancheFuji,
  avalancheFuji as wagmiAvalancheFuji,
  Chain as WagmiChain
} from 'wagmi/chains'

export enum TokenMillChainId {
  FUJI = avalancheFuji.id,
  AVALANCHE = wagmiAvalanche.id,
  SOLANA_DEVNET = 901,
  SOLANA = 900
}

type Chain = {
  dexbarnId: DexbarnChain
  iconUrl: string
  slug: string
} & WagmiChain

const avalanche: Chain = {
  ...wagmiAvalanche,
  blockExplorers: {
    default: {
      apiUrl: 'https://api.snowscan.xyz/api',
      name: 'SnowScan',
      url: 'https://snowscan.xyz'
    }
  },
  dexbarnId: 'avalanche',
  iconUrl: AvalancheIcon,
  slug: 'avalanche'
}

const fuji: Chain = {
  ...wagmiAvalancheFuji,
  blockExplorers: {
    default: {
      apiUrl: 'https://api-testnet.snowscan.xyz/api',
      name: 'SnowScan',
      url: 'https://testnet.snowscan.xyz'
    }
  },
  dexbarnId: 'avalanche',
  iconUrl: AvalancheIcon,
  slug: 'fuji'
}

export const IS_TESTNET = process.env.REACT_APP_IS_TESTNET === 'true'

export const chains = IS_TESTNET ? ([fuji] as const) : ([avalanche] as const)
