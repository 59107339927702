import { useWallet } from '@solana/wallet-adapter-react'
import { useConnection } from '@solana/wallet-adapter-react'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { VestingSchedule } from 'types/market'
import { SolbarnUserVestingPosition } from 'types/solbarn'
import { getReleasableAmount } from 'utils/vesting'
import { formatUnits, parseUnits } from 'viem'

export interface UseGetMarketVestingsFromSolbarnProps {
  enabled: boolean
  marketAddress?: string
  pageSize?: number
}

const useGetMarketVestingsFromSolbarn = ({
  enabled,
  marketAddress,
  pageSize = 20
}: UseGetMarketVestingsFromSolbarnProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()

  const fetchUserStakingPositions = useSolbarnGet<SolbarnUserVestingPosition[]>(
    `/v1/vesting/market/${marketAddress}`
  )

  const results = useInfiniteQuery<VestingSchedule[]>({
    enabled: enabled && !!marketAddress,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchUserStakingPositions({
        params: {
          market_id: marketAddress,
          page_num: pageParam,
          page_size: pageSize
        }
      })

      return data.map((position, index): VestingSchedule => {
        const currentTime = Math.floor(Date.now() / 1000)
        const elapsedTime = Math.max(0, currentTime - position.vesting_start)

        let releasableAmount = 0
        if (elapsedTime > position.cliff_duration) {
          const vestedTime = Math.min(
            elapsedTime - position.cliff_duration,
            position.vesting_duration
          )
          const vestedPercentage = vestedTime / position.vesting_duration
          const totalVestedAmount = position.vesting_amount * vestedPercentage
          releasableAmount = Math.max(
            totalVestedAmount - position.amount_released,
            0
          )
        }

        return {
          baseTokenAddress: position.base_token.address,
          beneficiary: position.user,
          cliffDuration: position.cliff_duration,
          index,
          releasable: {
            formatted: releasableAmount.toString(),
            value: parseUnits(releasableAmount.toString(), 6)
          },
          released: {
            formatted: position.amount_released.toString(),
            value: parseUnits(position.amount_released.toString(), 6)
          },
          start: position.vesting_start,
          total: {
            formatted: position.vesting_amount.toString(),
            value: parseUnits(position.vesting_amount.toString(), 6)
          },
          vestingDuration: position.vesting_duration,
          vestingPlanId: position.id
        }
      })
    },
    queryKey: ['GetMarketVestingsFromSolbarn', pageSize, marketAddress]
  })

  const vestings = useMemo(() => {
    return results.data?.pages?.flat()
  }, [results.data?.pages])

  const vestingsKeys = useMemo(() => {
    return vestings?.map((vesting) => vesting.vestingPlanId)
  }, [vestings])

  const { data: releasableAmounts } = useQuery({
    enabled: !!vestings && !!marketAddress && !!wallet.publicKey,
    queryFn: async () => {
      if (!marketAddress || !wallet.publicKey || !vestings) {
        return []
      }

      return await Promise.allSettled(
        vestings.map((vesting) =>
          getReleasableAmount({
            baseTokenAddress: vesting.baseTokenAddress,
            connection,
            marketAddress,
            vestingPlanId: vesting.vestingPlanId,
            walletPublicKey: wallet.publicKey!
          })
        )
      )
    },
    queryKey: [
      'GetReleasableAmount',
      vestingsKeys,
      marketAddress,
      wallet.publicKey?.toString()
    ]
  })

  return {
    isLoading: results.isLoading,
    refetch: results.refetch,
    vestings: useMemo(() => {
      return vestings?.map((vesting, index) => {
        const releasableAmount =
          releasableAmounts && releasableAmounts[index].status === 'fulfilled'
            ? (releasableAmounts[index] as PromiseFulfilledResult<bigint>).value
            : undefined

        return {
          ...vesting,
          releasable:
            releasableAmount !== undefined
              ? {
                  formatted: formatUnits(releasableAmount, 6),
                  value: releasableAmount
                }
              : vesting.releasable
        }
      })
    }, [vestings, releasableAmounts])
  }
}

export default useGetMarketVestingsFromSolbarn
