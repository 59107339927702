import { BN } from '@coral-xyz/anchor'
import { PublicKey } from '@solana/web3.js'
import { IS_TESTNET } from 'constants/chains'

export const SCALE = new BN(1e10)

export const TM_CONFIG = IS_TESTNET
  ? new PublicKey('8924mCgUTs7DE9UzNmN6bNFAdKmviqqe22Cx2C1abbPf')
  : new PublicKey('EVEVHBNUQ1gVG3LEMuJJov563CghdPdBB5nXNDHpHEA1')

export const TM_PROTOCOL_FEE_RECIPIENT = IS_TESTNET
  ? new PublicKey('7zin4bf28fEvUTbcvuDXVff7MvkTXahQRAYHJPMHLVWZ')
  : new PublicKey('CTCLCutCXYxGFdCCEg9o4s8bXC4LAiyvnuYuuMY9ycWD')

export const METAPLEX_METADATA_PROGRAM = new PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'
)
