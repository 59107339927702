import { Chain } from 'types/dexbarn'
import { CurveType, PricePoint } from 'utils/bondingCurves'

export const DEFAULT_ASK_PRICES: {
  [chain in Chain]: { [curveType in CurveType]: PricePoint[] }
} = {
  avalanche: {
    exponential: [
      { price: 0, supply: 0 },
      { price: 0.00000000131, supply: 100000000 },
      { price: 0.00000000827, supply: 200000000 },
      { price: 0.0000000451, supply: 300000000 },
      { price: 0.00000024, supply: 400000000 },
      { price: 0.00000127, supply: 500000000 },
      { price: 0.00000674, supply: 600000000 },
      { price: 0.0000357, supply: 700000000 },
      { price: 0.000189, supply: 800000000 },
      { price: 0.001, supply: 900000000 },
      { price: 0.005, supply: 1000000000 }
    ],
    logarithmic: [
      { price: 0, supply: 0 },
      { price: 0.000354, supply: 100000000 },
      { price: 0.000529, supply: 200000000 },
      { price: 0.000646, supply: 300000000 },
      { price: 0.000735, supply: 400000000 },
      { price: 0.000806, supply: 500000000 },
      { price: 0.000865, supply: 600000000 },
      { price: 0.000916, supply: 700000000 },
      { price: 0.00096, supply: 800000000 },
      { price: 0.001, supply: 900000000 },
      { price: 0.005, supply: 1000000000 }
    ],
    power: [
      { price: 0, supply: 0 },
      { price: 0.00000107, supply: 100000000 },
      { price: 0.00000341, supply: 200000000 },
      { price: 0.0000085, supply: 300000000 },
      { price: 0.0000196, supply: 400000000 },
      { price: 0.0000437, supply: 500000000 },
      { price: 0.0000961, supply: 600000000 },
      { price: 0.00021, supply: 700000000 },
      { price: 0.000459, supply: 800000000 },
      { price: 0.001, supply: 900000000 },
      { price: 0.005, supply: 1000000000 }
    ],
    quadratic: [
      { price: 0, supply: 0 },
      { price: 0.0000123, supply: 100000000 },
      { price: 0.0000494, supply: 200000000 },
      { price: 0.000111, supply: 300000000 },
      { price: 0.000198, supply: 400000000 },
      { price: 0.000309, supply: 500000000 },
      { price: 0.000444, supply: 600000000 },
      { price: 0.000605, supply: 700000000 },
      { price: 0.00079, supply: 800000000 },
      { price: 0.001, supply: 900000000 },
      { price: 0.005, supply: 1000000000 }
    ]
  },
  solana: {
    exponential: [
      { price: 0, supply: 0 },
      { price: 0.000000000328, supply: 100000000 },
      { price: 0.00000000207, supply: 200000000 },
      { price: 0.0000000113, supply: 300000000 },
      { price: 0.00000006, supply: 400000000 },
      { price: 0.000000318, supply: 500000000 },
      { price: 0.00000168, supply: 600000000 },
      { price: 0.00000892, supply: 700000000 },
      { price: 0.0000472, supply: 800000000 },
      { price: 0.00025, supply: 900000000 },
      { price: 0.00125, supply: 1000000000 }
    ],
    logarithmic: [
      { price: 0, supply: 0 },
      { price: 0.0000884, supply: 100000000 },
      { price: 0.000132, supply: 200000000 },
      { price: 0.000162, supply: 300000000 },
      { price: 0.000184, supply: 400000000 },
      { price: 0.000201, supply: 500000000 },
      { price: 0.000216, supply: 600000000 },
      { price: 0.000229, supply: 700000000 },
      { price: 0.00024, supply: 800000000 },
      { price: 0.00025, supply: 900000000 },
      { price: 0.00125, supply: 1000000000 }
    ],
    power: [
      { price: 0, supply: 0 },
      { price: 0.000000268, supply: 100000000 },
      { price: 0.000000853, supply: 200000000 },
      { price: 0.00000212, supply: 300000000 },
      { price: 0.00000489, supply: 400000000 },
      { price: 0.0000109, supply: 500000000 },
      { price: 0.000024, supply: 600000000 },
      { price: 0.0000526, supply: 700000000 },
      { price: 0.000115, supply: 800000000 },
      { price: 0.00025, supply: 900000000 },
      { price: 0.00125, supply: 1000000000 }
    ],
    quadratic: [
      { price: 0, supply: 0 },
      { price: 0.00000309, supply: 100000000 },
      { price: 0.0000123, supply: 200000000 },
      { price: 0.0000278, supply: 300000000 },
      { price: 0.0000494, supply: 400000000 },
      { price: 0.0000772, supply: 500000000 },
      { price: 0.000111, supply: 600000000 },
      { price: 0.000151, supply: 700000000 },
      { price: 0.000198, supply: 800000000 },
      { price: 0.00025, supply: 900000000 },
      { price: 0.00125, supply: 1000000000 }
    ]
  }
}
