import {
  Button,
  Flex,
  Hide,
  HStack,
  Link,
  Show,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import CopyReferredURLButton from 'components/CopyReferredURLButton'
import useGetUserReferralCodes from 'hooks/referrals/useGetUserReferralCodes'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Chain } from 'types/dexbarn'

import TokenInfoSection from './TokenInfoSection'

interface TokenMillDetailHeaderProps {
  chain: Chain
  isCreator: boolean
  market: ReturnType<typeof useGetMarket>['data']
  marketAddress: string
}

const TokenMillDetailHeader = ({
  chain,
  isCreator,
  market,
  marketAddress
}: TokenMillDetailHeaderProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { data: userReferralCodes } = useGetUserReferralCodes()

  const getTabIndex = () => {
    if (location.pathname.includes('/stake')) return 1
    if (location.pathname.includes('/vesting')) return 2
    return 0
  }

  const handleTabChange = (index: number) => {
    switch (index) {
      case 0:
        navigate(`/${chain}/${marketAddress}`)
        break
      case 1:
        navigate(`/${chain}/${marketAddress}/stake`)
        break
      case 2:
        navigate(`/${chain}/${marketAddress}/vesting`)
        break
    }
  }

  return (
    <Stack
      w="full"
      spacing={4}
      px={{ base: 4, md: 6 }}
      pt={{ base: 4, md: 6 }}
      flexDir={{ base: 'column', md: 'row' }}
      justify="space-between"
      align="flex-start"
    >
      <VStack align="flex-start" fontSize="sm" spacing={2}>
        <Flex gap={2} flexDir="column" align="flex-start">
          <Link as={NavLink} to="/" textColor="textSecondary">
            {`<- Back to Tokens`}
          </Link>
        </Flex>

        <Hide above="md">
          <TokenInfoSection
            chain={chain}
            market={market}
            isCreator={isCreator}
          />
        </Hide>

        <Hide below="md">
          <Tabs variant="line" index={getTabIndex()} onChange={handleTabChange}>
            <TabList>
              <Tab>Trade</Tab>
              <Tab>Stake</Tab>
              <Tab>Vesting</Tab>
            </TabList>
          </Tabs>
        </Hide>
      </VStack>

      <VStack
        align={{ base: 'flex-start', md: 'flex-end' }}
        w={{ base: 'full', md: 'auto' }}
      >
        <HStack>
          <CopyReferredURLButton
            code={userReferralCodes?.[chain] || ''}
            chain={chain}
            marketAddress={marketAddress}
            buttonSize={{ base: 'xs', md: 'sm' }}
          />
          <Link as={NavLink} to="/referrals">
            <Button
              size={{ base: 'xs', md: 'sm' }}
              variant="boxShadowFlat"
              _dark={{ bg: 'bgSecondary' }}
            >
              [Refer Others]
            </Button>
          </Link>
        </HStack>
        <Text fontSize="xs" textColor="textSecondary">
          Invite your friends and get 50% fee share
        </Text>
      </VStack>

      <Show below="md">
        <Tabs
          variant="line"
          index={getTabIndex()}
          onChange={handleTabChange}
          mt={-1}
        >
          <TabList>
            <Tab>Trade</Tab>
            <Tab>Stake</Tab>
            <Tab>Vesting</Tab>
          </TabList>
        </Tabs>
      </Show>
    </Stack>
  )
}

export default TokenMillDetailHeader
