import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useMutation } from '@tanstack/react-query'
import { UseReleaseTokensProps } from 'hooks/tokenmill/useReleaseTokens'
import useTransactionToast from 'hooks/useTransactionToast'
import { useFeeMode, useMaxTransactionFee } from 'state/settings/hooks'
import { buildTransactionWithPriorityFee } from 'utils/transaction'
import { getReleaseTokensInstructions } from 'utils/vesting'

const useReleaseTokensSolana = ({
  baseTokenAddress,
  marketAddress,
  vestingPlanId
}: UseReleaseTokensProps) => {
  const wallet = useWallet()
  const { connection } = useConnection()
  const addTransactionToast = useTransactionToast()
  const { maxTransactionFee } = useMaxTransactionFee()
  const { feeMode } = useFeeMode()

  const releaseTokens = async () => {
    if (!wallet.publicKey) throw new Error('Wallet not connected')
    const instructions = await getReleaseTokensInstructions({
      baseTokenAddress,
      connection,
      marketAddress,
      vestingPlanId,
      walletPublicKey: wallet.publicKey
    })

    const { latestBlockhash, transaction } =
      await buildTransactionWithPriorityFee(
        connection,
        instructions,
        wallet.publicKey,
        maxTransactionFee,
        feeMode
      )

    const signature = await wallet.sendTransaction(transaction, connection)

    addTransactionToast({
      chain: 'solana',
      description: 'Unlocked tokens',
      hash: signature,
      walletAddress: wallet.publicKey.toBase58()
    })

    const result = await connection.confirmTransaction(
      {
        blockhash: latestBlockhash.blockhash,
        lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
        signature
      },
      'processed'
    )

    if (result.value.err) {
      throw new Error(result.value.err.toString())
    }

    return signature
  }

  const mutation = useMutation({
    mutationFn: releaseTokens
  })

  return {
    isReleasing: mutation.isPending,
    releaseTokensAsync: mutation.mutateAsync
  }
}

export default useReleaseTokensSolana
