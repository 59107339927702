import { IS_TESTNET, TokenMillChainId } from 'constants/chains'
import { ChainType } from 'types/chain'
import { Chain } from 'types/dexbarn'

export const getDexbarnChainParam = (chainId: TokenMillChainId): Chain => {
  switch (chainId) {
    case TokenMillChainId.FUJI:
    case TokenMillChainId.AVALANCHE:
      return 'avalanche'
    case TokenMillChainId.SOLANA_DEVNET:
    case TokenMillChainId.SOLANA:
      return 'solana'
  }
}

export const getBlockExplorer = (
  value: string,
  chain: Chain,
  type: 'tx' | 'address'
) => {
  switch (chain) {
    case 'solana':
      return {
        name: 'Solana Explorer',
        url: IS_TESTNET
          ? `https://solscan.io/${type}/${value}?cluster=devnet`
          : `https://solscan.io/${type}/${value}`
      }
    case 'avalanche':
      return {
        name: 'SnowScan',
        url: IS_TESTNET
          ? `https://testnet.snowscan.xyz/${type}/${value}`
          : `https://snowscan.xyz/${type}/${value}`
      }
  }
}

export const getChainId = (chain: Chain): TokenMillChainId => {
  switch (chain) {
    case 'solana':
      return IS_TESTNET
        ? TokenMillChainId.SOLANA_DEVNET
        : TokenMillChainId.SOLANA
    case 'avalanche':
      return IS_TESTNET ? TokenMillChainId.FUJI : TokenMillChainId.AVALANCHE
  }
}

export const getChainType = (chain: Chain): ChainType => {
  switch (chain) {
    case 'avalanche':
      return 'evm'
    case 'solana':
      return 'solana'
  }
}
