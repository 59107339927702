import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Hide,
  IconButton,
  Show,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import useGetMarketsFromBarn from 'hooks/barn/evm/useGetMarketsFromBarn'
import useGetMarketsFromSolbarn from 'hooks/barn/solana/useGetMarketsFromSolbarn'
import useLiveMarketsEVM from 'hooks/evm/useLiveMarketsEVM'
import React, { useMemo, useState } from 'react'
import useLiveMarketsSolana from 'solana/hooks/useLiveMarketsSolana'
import { useChainView, useIsHeaderCollapsed } from 'state/settings/hooks'
import { TMMarketQueryParam } from 'types/dexbarn'

import HomeHeader from './HomeHeader'
import LiveIndicator from './LiveIndicator'
import MarketCard from './MarketCard'
import MarketsSortMenu from './MarketsSortMenu'
import MarketsTable from './MarketsTable'

const filterOptions: TMMarketQueryParam.FilterBy[] = ['5m', '1h', '6h', '1d']

export type SortState = {
  filterBy: TMMarketQueryParam.FilterBy
  isDescending: boolean
  method: TMMarketQueryParam.OrderBy
}

const MarketsListEVM = () => {
  const [sortState, setSortState] = useState<SortState>({
    filterBy: '6h',
    isDescending: true,
    method: 'trending'
  })

  const [isPaused, setIsPaused] = useState<boolean>(false)
  const isLive = !isPaused && sortState.method === 'new'

  const {
    data: barnData,
    fetchNextPage: fetchNextPageBarn,
    hasNextPage: hasNextPageBarn,
    isFetchingNextPage: isFetchingNextPageBarn,
    isLoading: isLoadingBarnMarkets
  } = useGetMarketsFromBarn({
    chain: 'avalanche',
    excludeLowVolumeMarkets: false,
    filterBy: sortState.filterBy,
    isDesc: sortState.isDescending,
    orderBy: sortState.method
  })

  const { data: liveMarkets, pausedMarketsCount } = useLiveMarketsEVM({
    chain: 'avalanche',
    isEnabled: true,
    isPaused: !isLive
  })

  const marketsEVM = useMemo(() => {
    const markets = barnData?.pages.flatMap((page) => page) || []

    if (sortState.method === 'new') {
      return [...liveMarkets, ...markets]
    }

    return markets
  }, [barnData, liveMarkets, sortState.method])

  return (
    <VStack align="stretch" spacing={4} w="full">
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        gap={4}
        w="full"
        alignItems={{ base: 'flex-start', md: 'center' }}
      >
        <MarketsSortMenu
          currentSort={sortState.method}
          currentSortDirection={sortState.isDescending}
          onSort={(method, isDesc) =>
            setSortState((prev) => ({
              ...prev,
              isDescending: isDesc,
              method
            }))
          }
        />

        <Tabs
          variant="bracketed"
          index={filterOptions.indexOf(sortState.filterBy)}
          onChange={(index) =>
            setSortState((prev) => ({
              ...prev,
              filterBy: filterOptions[index]
            }))
          }
          overflowX={{ base: 'auto', md: 'visible' }}
          w={{ base: 'full', md: 'auto' }}
          isFitted
        >
          <TabList>
            {filterOptions.map((option) => (
              <Tab key={option} whiteSpace="nowrap">
                {option.toUpperCase()}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Spacer />

        {sortState.method === 'new' ? (
          <LiveIndicator
            isPaused={!isLive}
            onTogglePause={() => setIsPaused(!isPaused)}
            pausedDataCount={pausedMarketsCount}
          />
        ) : null}
      </Flex>

      {marketsEVM.map((market) => (
        <MarketCard key={market.marketAddress} market={market} />
      ))}

      {hasNextPageBarn && !isLoadingBarnMarkets && (
        <Center mt={4} w="full">
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextPageBarn}
            onClick={() => fetchNextPageBarn()}
          >
            Load More
          </Button>
        </Center>
      )}
    </VStack>
  )
}

const MarketsListSolana = () => {
  const [sortState, setSortState] = useState<SortState>({
    filterBy: '6h',
    isDescending: true,
    method: 'trending'
  })

  const [isPaused, setIsPaused] = useState<boolean>(false)
  const isLive = !isPaused && sortState.method === 'new'

  const {
    data: solbarnData,
    fetchNextPage: fetchNextPageSolbarn,
    hasNextPage: hasNextPageSolbarn,
    isFetchingNextPage: isFetchingNextPageSolbarn,
    isLoading: isLoadingSolbarnMarkets
  } = useGetMarketsFromSolbarn({
    excludeLowVolumeMarkets: false,
    filterBy: sortState.filterBy,
    isDesc: sortState.isDescending,
    orderBy: sortState.method
  })

  const { data: liveMarkets, pausedMarketsCount } = useLiveMarketsSolana({
    isEnabled: true,
    isPaused: !isLive
  })

  const marketsSolana = useMemo(() => {
    if (sortState.method === 'new') {
      return [...liveMarkets, ...(solbarnData?.pages.flat() || [])]
    }

    return solbarnData?.pages.flat() || []
  }, [solbarnData, liveMarkets, sortState.method])

  return (
    <VStack align="stretch" spacing={4} w="full">
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        gap={4}
        w="full"
        alignItems={{ base: 'flex-start', md: 'center' }}
      >
        <MarketsSortMenu
          currentSort={sortState.method}
          currentSortDirection={sortState.isDescending}
          onSort={(method, isDesc) =>
            setSortState((prev) => ({ ...prev, isDescending: isDesc, method }))
          }
        />

        <Tabs
          variant="bracketed"
          index={filterOptions.indexOf(sortState.filterBy)}
          onChange={(index) =>
            setSortState((prev) => ({
              ...prev,
              filterBy: filterOptions[index]
            }))
          }
          overflowX={{ base: 'auto', md: 'visible' }}
          w={{ base: 'full', md: 'auto' }}
          isFitted
        >
          <TabList>
            {filterOptions.map((option) => (
              <Tab key={option} whiteSpace="nowrap">
                {option.toUpperCase()}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Spacer />

        {sortState.method === 'new' ? (
          <LiveIndicator
            isPaused={!isLive}
            onTogglePause={() => setIsPaused(!isPaused)}
            pausedDataCount={pausedMarketsCount}
          />
        ) : null}
      </Flex>

      {marketsSolana.map((market) => (
        <MarketCard key={market.marketAddress} market={market} />
      ))}

      {hasNextPageSolbarn && !isLoadingSolbarnMarkets && (
        <Center mt={4} w="full">
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextPageSolbarn}
            onClick={() => fetchNextPageSolbarn()}
          >
            Load More
          </Button>
        </Center>
      )}
    </VStack>
  )
}

const MarketsTableSolana = () => {
  const [sortState, setSortState] = useState<SortState>({
    filterBy: '6h',
    isDescending: true,
    method: 'trending'
  })

  const [isPaused, setIsPaused] = useState<boolean>(false)
  const isLive = !isPaused && sortState.method === 'new'

  const {
    data: solbarnData,
    fetchNextPage: fetchNextPageSolbarn,
    hasNextPage: hasNextPageSolbarn,
    isFetchingNextPage: isFetchingNextPageSolbarn,
    isLoading: isLoadingSolbarnMarkets
  } = useGetMarketsFromSolbarn({
    excludeLowVolumeMarkets: false,
    filterBy: sortState.filterBy,
    isDesc: sortState.isDescending,
    orderBy: sortState.method
  })

  const { data: liveMarkets, pausedMarketsCount } = useLiveMarketsSolana({
    isEnabled: true,
    isPaused: !isLive
  })

  const marketsSolana = useMemo(() => {
    if (sortState.method === 'new') {
      return [...liveMarkets, ...(solbarnData?.pages.flat() || [])]
    }

    return solbarnData?.pages.flat() || []
  }, [solbarnData, liveMarkets, sortState.method])

  const onHeaderClick = (method: TMMarketQueryParam.OrderBy) => {
    setSortState((prev) => ({
      ...prev,
      isDescending: method === prev.method ? !prev.isDescending : true,
      method: method === prev.method ? prev.method : method
    }))
  }

  let sortMethodTabIndex = -1
  if (sortState.method === 'new') {
    sortMethodTabIndex = 0
  } else if (sortState.method === 'trending') {
    sortMethodTabIndex = 1
  }

  return (
    <Box>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        gap={{ base: 4, md: 8 }}
        w="full"
        alignItems="center"
      >
        <Tabs
          variant="bracketed"
          index={sortMethodTabIndex}
          overflowX={{ base: 'auto', md: 'visible' }}
          w={{ base: 'full', md: 'auto' }}
        >
          <TabList>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: '5m',
                  isDescending: true,
                  method: 'new'
                })
              }}
              whiteSpace="nowrap"
            >
              New
            </Tab>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: '6h',
                  isDescending: true,
                  method: 'trending'
                })
              }}
              whiteSpace="nowrap"
            >
              Trending
            </Tab>
          </TabList>
        </Tabs>

        <Tabs
          variant="bracketed"
          index={filterOptions.indexOf(sortState.filterBy)}
          onChange={(index) =>
            setSortState((prev) => ({
              ...prev,
              filterBy: filterOptions[index]
            }))
          }
          overflowX={{ base: 'auto', md: 'visible' }}
          w={{ base: 'full', md: 'auto' }}
        >
          <TabList>
            {filterOptions.map((option) => (
              <Tab key={option} whiteSpace="nowrap">
                {option.toUpperCase()}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Spacer />

        {sortState.method === 'new' ? (
          <LiveIndicator
            isPaused={!isLive}
            onTogglePause={() => setIsPaused(!isPaused)}
            pausedDataCount={pausedMarketsCount}
          />
        ) : null}
      </Flex>

      <MarketsTable
        markets={marketsSolana}
        sortMethod={sortState.method}
        isSortDescending={sortState.isDescending}
        onHeaderClick={onHeaderClick}
      />

      {hasNextPageSolbarn && !isLoadingSolbarnMarkets && (
        <Center mt={4} w="full">
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextPageSolbarn}
            onClick={() => fetchNextPageSolbarn()}
          >
            Load More
          </Button>
        </Center>
      )}
    </Box>
  )
}

const MarketsTableEVM = () => {
  const [sortState, setSortState] = useState<SortState>({
    filterBy: '6h',
    isDescending: true,
    method: 'trending'
  })

  const [isPaused, setIsPaused] = useState<boolean>(false)
  const isLive = !isPaused && sortState.method === 'new'

  const {
    data: barnData,
    fetchNextPage: fetchNextPageBarn,
    hasNextPage: hasNextPageBarn,
    isFetchingNextPage: isFetchingNextPageBarn,
    isLoading: isLoadingBarnMarkets
  } = useGetMarketsFromBarn({
    chain: 'avalanche',
    excludeLowVolumeMarkets: false,
    filterBy: sortState.filterBy,
    isDesc: sortState.isDescending,
    orderBy: sortState.method
  })

  const { data: liveMarkets, pausedMarketsCount } = useLiveMarketsEVM({
    chain: 'avalanche',
    isEnabled: true,
    isPaused: !isLive
  })

  const marketsEVM = useMemo(() => {
    if (sortState.method === 'new') {
      return [
        ...liveMarkets,
        ...(barnData?.pages.flatMap((page) => page) || [])
      ]
    }

    return barnData?.pages.flatMap((page) => page) || []
  }, [barnData, liveMarkets, sortState.method])

  const onHeaderClick = (method: TMMarketQueryParam.OrderBy) => {
    setSortState((prev) => ({
      ...prev,
      isDescending: method === prev.method ? !prev.isDescending : true,
      method: method === prev.method ? prev.method : method
    }))
  }

  let sortMethodTabIndex = -1
  if (sortState.method === 'new') {
    sortMethodTabIndex = 0
  } else if (sortState.method === 'trending') {
    sortMethodTabIndex = 1
  }

  return (
    <Box>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        gap={{ base: 4, md: 8 }}
        w="full"
        alignItems="center"
      >
        <Tabs
          variant="bracketed"
          index={sortMethodTabIndex}
          overflowX={{ base: 'auto', md: 'visible' }}
          w={{ base: 'full', md: 'auto' }}
        >
          <TabList>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: '5m',
                  isDescending: true,
                  method: 'new'
                })
              }}
              whiteSpace="nowrap"
            >
              New
            </Tab>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: '6h',
                  isDescending: true,
                  method: 'trending'
                })
              }}
              whiteSpace="nowrap"
            >
              Trending
            </Tab>
          </TabList>
        </Tabs>

        <Tabs
          variant="bracketed"
          index={filterOptions.indexOf(sortState.filterBy)}
          onChange={(index) =>
            setSortState((prev) => ({
              ...prev,
              filterBy: filterOptions[index]
            }))
          }
          overflowX={{ base: 'auto', md: 'visible' }}
          w={{ base: 'full', md: 'auto' }}
        >
          <TabList>
            {filterOptions.map((option) => (
              <Tab key={option} whiteSpace="nowrap">
                {option.toUpperCase()}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Spacer />

        {sortState.method === 'new' ? (
          <LiveIndicator
            isPaused={!isLive}
            onTogglePause={() => setIsPaused(!isPaused)}
            pausedDataCount={pausedMarketsCount}
          />
        ) : null}
      </Flex>

      <MarketsTable
        markets={marketsEVM}
        sortMethod={sortState.method}
        isSortDescending={sortState.isDescending}
        onHeaderClick={onHeaderClick}
      />

      {hasNextPageBarn && !isLoadingBarnMarkets && (
        <Center mt={4} w="full">
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextPageBarn}
            onClick={() => fetchNextPageBarn()}
          >
            Load More
          </Button>
        </Center>
      )}
    </Box>
  )
}

const TokenMill = () => {
  const { chainView, updateChainView } = useChainView()

  const { isHeaderCollapsed, updateIsHeaderCollapsed } = useIsHeaderCollapsed()

  let chainViewTabIndex = -1
  let mobileChainViewTabIndex = -1
  if (chainView === 'sol') {
    chainViewTabIndex = 0
    mobileChainViewTabIndex = 0
  } else if (chainView === 'avax') {
    chainViewTabIndex = 1
    mobileChainViewTabIndex = 1
  } else if (chainView === 'split') {
    chainViewTabIndex = 2
    mobileChainViewTabIndex = 0
  }

  return (
    <Box maxW="1600px" margin="0 auto" pb={8}>
      {!isHeaderCollapsed && <HomeHeader />}

      <Hide below="lg">
        <VStack
          align="flex-start"
          pt={isHeaderCollapsed ? 4 : 0}
          px={{ base: 0, md: 2 }}
          spacing={4}
        >
          <Tabs
            variant="bracketed"
            overflowX={{ base: 'auto', md: 'visible' }}
            w="full"
            index={chainViewTabIndex}
            isLazy
          >
            <Center gap={0}>
              <TabList>
                <Tab whiteSpace="nowrap" onClick={() => updateChainView('sol')}>
                  SOL
                </Tab>
                <Tab
                  whiteSpace="nowrap"
                  onClick={() => updateChainView('avax')}
                >
                  AVAX
                </Tab>
                <Tab
                  whiteSpace="nowrap"
                  onClick={() => updateChainView('split')}
                >
                  Split View
                </Tab>
              </TabList>

              <Tooltip label="collapse/expand page header">
                <IconButton
                  aria-label="expand / collapse page header"
                  variant="boxShadowFlat"
                  bg="bgSecondary"
                  icon={
                    isHeaderCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />
                  }
                  onClick={() => updateIsHeaderCollapsed(!isHeaderCollapsed)}
                />
              </Tooltip>
            </Center>

            <TabPanels>
              <TabPanel>
                <MarketsTableSolana />
              </TabPanel>
              <TabPanel>
                <MarketsTableEVM />
              </TabPanel>
              <TabPanel>
                <Grid
                  templateColumns={{
                    base: 'minmax(0, 1fr)',
                    lg: 'repeat(2, minmax(0, 1fr))'
                  }}
                  gap={4}
                  w="full"
                >
                  <MarketsListEVM />
                  <MarketsListSolana />
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Hide>

      <Show below="lg">
        <VStack align="flex-start" spacing={4}>
          <Tabs
            variant="bracketed"
            w="full"
            index={mobileChainViewTabIndex}
            isLazy
            isFitted
          >
            <TabList px={4}>
              <Tab whiteSpace="nowrap" onClick={() => updateChainView('sol')}>
                SOL
              </Tab>
              <Tab whiteSpace="nowrap" onClick={() => updateChainView('avax')}>
                AVAX
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <VStack>
                  <MarketsListSolana />
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <MarketsListEVM />
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Show>
    </Box>
  )
}

export default TokenMill
