import { Button } from '@chakra-ui/button'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal'
import {
  Divider,
  HStack,
  Image,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { WalletName } from '@solana/wallet-adapter-base'
import { useWallet } from '@solana/wallet-adapter-react'
import CoinbaseWalletIcon from 'assets/coinbaseWallet.svg'
import WalletConnectIcon from 'assets/walletConnectWallet.svg'
import React from 'react'
import { useAreTermsAccepted } from 'state/settings/hooks'
import { Connector, useAccount, useConnect } from 'wagmi'

import Disclaimer from './Disclaimer'

interface ConnectWalletModalProps {
  isOpen: boolean
  onClose: () => void
}

const ConnectWalletModal = ({ isOpen, onClose }: ConnectWalletModalProps) => {
  const { areTermsAccepted } = useAreTermsAccepted()
  const { connect, connectors } = useConnect()
  const { connector: activeConnector } = useAccount()

  const { select, wallet: activeWallet, wallets } = useWallet()

  const handleEvmWalletConnect = (connector: Connector) => {
    connect({ connector })
    onClose()
  }

  const handleSolanaWalletConnect = (walletName: WalletName) => {
    select(walletName)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay zIndex="calc(var(--wcm-z-index) - 1)" />
      <ModalContent
        containerProps={{
          zIndex: 'calc(var(--wcm-z-index) - 1)'
        }}
      >
        <ModalHeader fontSize="lg">Select a Wallet</ModalHeader>
        <ModalCloseButton bg="transparent" />

        <ModalBody>
          <Tabs isFitted>
            <TabList>
              <Tab>EVM</Tab>
              <Tab>Solana</Tab>
            </TabList>

            <TabPanels pt={{ base: 4, md: 6 }}>
              <TabPanel p={0}>
                <VStack spacing={4} align="stretch">
                  <SimpleGrid columns={1} spacing={2}>
                    {connectors.toReversed().map((connector) => {
                      const iconSrc =
                        connector.icon ||
                        (connector.type === 'coinbaseWallet'
                          ? CoinbaseWalletIcon
                          : connector.type === 'walletConnect'
                            ? WalletConnectIcon
                            : '')

                      const isDetected =
                        connector.type !== 'walletConnect' &&
                        connector.type !== 'coinbaseWallet'

                      return (
                        <Button
                          key={connector.id}
                          variant="boxShadowFlat"
                          bg="bgSecondary"
                          onClick={() => handleEvmWalletConnect(connector)}
                          isDisabled={!areTermsAccepted}
                          leftIcon={
                            <Image
                              src={iconSrc}
                              boxSize={6}
                              ml={1}
                              mr={2}
                              borderRadius={4}
                            />
                          }
                          textAlign="left"
                          justifyContent="flex-start"
                        >
                          <HStack justify="space-between" width="100%">
                            <Text>{connector.name}</Text>
                            {activeConnector?.id === connector.id ? (
                              <Text fontSize="sm" color="green.400">
                                Connected
                              </Text>
                            ) : isDetected ? (
                              <Text fontSize="sm" color="textSecondary">
                                Detected
                              </Text>
                            ) : null}
                          </HStack>
                        </Button>
                      )
                    })}
                  </SimpleGrid>
                </VStack>
              </TabPanel>
              <TabPanel p={0}>
                <VStack spacing={4} align="stretch">
                  <SimpleGrid columns={1} spacing={2}>
                    {wallets.map((wallet) => (
                      <Button
                        key={wallet.adapter.name}
                        variant="boxShadowFlat"
                        bg="bgSecondary"
                        onClick={() =>
                          handleSolanaWalletConnect(wallet.adapter.name)
                        }
                        isDisabled={!areTermsAccepted}
                        leftIcon={
                          <Image
                            src={wallet.adapter.icon}
                            boxSize={6}
                            ml={1}
                            mr={2}
                            borderRadius={4}
                          />
                        }
                        textAlign="left"
                        justifyContent="flex-start"
                      >
                        <HStack justify="space-between" width="100%">
                          <Text>{wallet.adapter.name}</Text>
                          {wallet.adapter.name ===
                          activeWallet?.adapter.name ? (
                            <Text fontSize="sm" color="green.400">
                              Connected
                            </Text>
                          ) : wallet.readyState === 'Installed' ? (
                            <Text fontSize="sm" color="textSecondary">
                              Detected
                            </Text>
                          ) : null}
                        </HStack>
                      </Button>
                    ))}
                  </SimpleGrid>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <VStack spacing={4}>
            <Divider />
            <Disclaimer />
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConnectWalletModal
