import {
  Box,
  Heading,
  HStack,
  InputGroup,
  InputRightElement,
  Tab,
  TabList,
  Tabs,
  Text
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import React from 'react'
import { useFeeMode, useMaxTransactionFee } from 'state/settings/hooks'
import { DEFAULT_MAX_TRANSACTION_FEE } from 'state/settings/reducer'

const TransactionSettings = () => {
  const { maxTransactionFee, updateMaxTransactionFee } = useMaxTransactionFee()
  const { feeMode, updateFeeMode } = useFeeMode()

  return (
    <Box w="320px">
      <Heading size="sm">Priority Fee</Heading>
      <Text mt={1} fontSize="xs" textColor="textSecondary">
        Priority fees will be applied to all Solana transactions.
      </Text>

      <HStack mt={4} justify="space-between">
        <Heading size="xs">Fee Mode</Heading>
        <Tabs
          variant="bracketed"
          index={feeMode === 'maxCap' ? 0 : 1}
          onChange={(index) =>
            updateFeeMode(index === 0 ? 'maxCap' : 'exactFee')
          }
        >
          <TabList h="30px">
            <Tab fontSize="xs">Max Cap</Tab>
            <Tab fontSize="xs" mr={0}>
              Exact Fee
            </Tab>
          </TabList>
        </Tabs>
      </HStack>

      <Text mt={2} fontSize="xs" textColor="textSecondary">
        {feeMode === 'maxCap'
          ? 'Priority fees are optimized and capped at a maximum.'
          : 'Priority fee will be set to the exact fee.'}
      </Text>

      <InputGroup mt={2} w="full">
        <NumericalInput
          inputType="decimal"
          value={maxTransactionFee}
          onValueChange={updateMaxTransactionFee}
          placeholder={DEFAULT_MAX_TRANSACTION_FEE}
          fontSize="sm"
        />
        <InputRightElement>SOL</InputRightElement>
      </InputGroup>
    </Box>
  )
}

export default TransactionSettings
