import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain, StakingMarketData } from 'types/dexbarn'

const useGetTopStakingPositions = ({
  chain,
  enabled,
  marketAddress
}: {
  chain: Chain
  enabled: boolean
  marketAddress: string
}) => {
  const fetchStakingPositions = useDexbarnGet<StakingMarketData>(
    `v1/staking/${chain}/market/${marketAddress}`
  )

  return useQuery<StakingMarketData>({
    enabled: !!marketAddress && enabled,
    queryFn: fetchStakingPositions,
    queryKey: ['stakingPositionsEVM', marketAddress, chain]
  })
}

export default useGetTopStakingPositions
