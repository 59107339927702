import {
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import useLiveTrades from 'hooks/tokenmill/useLiveTrades'
import LiveIndicator from 'pages/TokenMill/LiveIndicator'
import React, { useState } from 'react'
import { Chain } from 'types/dexbarn'

import TokenMillRecentTrades from './TokenMillRecentTrades'
import TopHolders from './TopHolders'

interface TokenMillDetailDataTabsProps {
  chain: Chain
  marketAddress: string
  market?: ReturnType<typeof useGetMarket>['data']
}

const TokenMillDetailDataTabs = ({
  chain,
  market,
  marketAddress
}: TokenMillDetailDataTabsProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [isPaused, setIsPaused] = useState<boolean>(false)
  const [isHoveringRow, setIsHoveringRow] = useState<boolean>(false)

  const { data: liveTrades, pausedDataCount } = useLiveTrades({
    baseTokenSymbol: market?.baseToken.symbol || '',
    chain,
    isPaused: isPaused || isHoveringRow,
    marketAddress
  })

  return (
    <Tabs
      isLazy
      variant="bracketed"
      w="full"
      pt={{ base: 4, md: 6 }}
      pb={{ base: 4, md: 6 }}
      index={selectedTab}
      onChange={(index) => setSelectedTab(index)}
    >
      <HStack justify="space-between" w="full">
        <TabList px={4}>
          <Tab>Recent Trades</Tab>
          <Tab>Top Holders</Tab>
        </TabList>

        {selectedTab === 0 && (
          <Box pr={4}>
            <LiveIndicator
              isPaused={isPaused || isHoveringRow}
              onTogglePause={() => setIsPaused(!isPaused)}
              pausedDataCount={pausedDataCount}
            />
          </Box>
        )}
      </HStack>

      <TabPanels>
        <TabPanel p={0}>
          <TokenMillRecentTrades
            marketAddress={marketAddress}
            chain={chain}
            marketCreator={market?.creator || ''}
            liveTrades={liveTrades}
            setIsHoveringRow={setIsHoveringRow}
          />
        </TabPanel>
        <TabPanel p={0}>
          {market ? (
            <TopHolders
              marketAddress={marketAddress}
              baseTokenAddress={market.baseToken.address}
              circulatingSupply={market.circulatingSupply}
              totalSupply={market.totalSupply}
              creatorAddress={market.creator}
              chain={chain}
            />
          ) : null}
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default TokenMillDetailDataTabs
