import { useToast, UseToastOptions } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import Popup from 'components/Popup'
import { useCallback } from 'react'
import React from 'react'

interface SuccessPopupOptions {
  blockExplorer?: { name: string; url: string }
  duration?: number
  subtitle?: string
  summary?: string
}

const useAddSuccessPopup = () => {
  const toast = useToast()

  const addSuccessPopup = useCallback(
    (options: SuccessPopupOptions) => {
      const {
        blockExplorer,
        duration = 5000,
        subtitle,
        summary = 'Success'
      } = options

      const toastOptions: UseToastOptions = {
        duration,
        isClosable: true,
        position: 'top-right',
        render: ({ id }) => (
          <Popup
            id={id}
            status="success"
            summary={summary}
            subtitle={subtitle}
            externalLink={
              blockExplorer
                ? {
                    name: t`View on ${blockExplorer?.name}`,
                    url: `${blockExplorer?.url}/tx/${id}`
                  }
                : undefined
            }
          />
        )
      }

      toast(toastOptions)
    },
    [toast]
  )

  return addSuccessPopup
}

export default useAddSuccessPopup
