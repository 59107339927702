import { useInfiniteQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { Chain, UserPortfolioItem } from 'types/dexbarn'
import { PortfolioToken } from 'types/portfolio'

interface UseGetUserPortfolioParams {
  chain: Chain
  pageSize?: number
  userAddress?: string
}

const useGetUserPortfolio = ({
  chain,
  pageSize = 20,
  userAddress
}: UseGetUserPortfolioParams) => {
  const fetchPortfolio = useDexbarnGet<UserPortfolioItem[]>(
    `/v1/users/portfolio/${chain}/${userAddress}`
  )

  const results = useInfiniteQuery<UserPortfolioItem[]>({
    enabled: !!userAddress,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      fetchPortfolio({
        params: {
          pageNum: pageParam,
          pageSize
        }
      }),
    queryKey: ['GetUserPortfolio', chain, userAddress, pageSize]
  })

  const data = useMemo(() => {
    const items = results.data?.pages.flatMap((page) => page) ?? []

    return items
      .map(
        (item) =>
          ({
            amount: Number(item.amount),
            chain,
            marketAddress: item.marketAddress,
            priceUsd: item.token.priceUsd,
            tokenAddress: item.token.address,
            tokenLogoURI: item.iconUrl,
            tokenSymbol: item.token.symbol,
            valueUsd: Number(item.amountUsd)
          }) satisfies PortfolioToken
      )
      .filter((token) => token.amount > 0)
  }, [results.data, chain])

  return {
    ...results,
    data
  }
}

export default useGetUserPortfolio
