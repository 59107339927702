import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { TokenHolder } from 'types/dexbarn'

interface UseGetTokenLargestHoldersProps {
  chain: string
  enabled: boolean
  tokenAddress?: string
}

const useGetTokenLargestHolders = ({
  chain,
  enabled,
  tokenAddress
}: UseGetTokenLargestHoldersProps) => {
  const fetchLargestHolders = useDexbarnGet<TokenHolder[]>(
    `v1/tokens/token-largest-accounts/${chain}/${tokenAddress}`
  )

  return useQuery<TokenHolder[]>({
    enabled: !!tokenAddress && enabled,
    queryFn: fetchLargestHolders,
    queryKey: ['tokenLargestHolders', chain, tokenAddress]
  })
}

export default useGetTokenLargestHolders
