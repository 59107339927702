import { useConnection } from '@solana/wallet-adapter-react'
import { useQueries } from '@tanstack/react-query'
import { IS_TESTNET } from 'constants/chains'
import { SOLBARN_TESTNET_WSS_URL, SOLBARN_WSS_URL } from 'constants/dexbarn'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Market } from 'types/market'
import { getResizedImageUrl } from 'utils/image'
import { getTokenMetadataWithFallback } from 'utils/token'

interface WSMarketData {
  base_token_id: string
  creator: string
  description: string | null
  icon_url: string | null
  id: string
  market_created_at: string
  name: string
  quote_token_id: string
  timestamp: number
  total_supply: string
}

const useLiveMarketsSolana = ({
  isEnabled,
  isPaused
}: {
  isEnabled: boolean
  isPaused: boolean
}) => {
  const [liveMarkets, setLiveMarkets] = useState<WSMarketData[]>([])
  const [lastPausedTimestamp, setLastPausedTimestamp] = useState<number | null>(
    null
  )
  const wsRef = useRef<WebSocket | null>(null)

  useEffect(() => {
    if (isPaused) {
      setLastPausedTimestamp(Date.now())
    } else {
      setLastPausedTimestamp(null)
    }
  }, [isPaused])

  useEffect(() => {
    if (!isEnabled) return

    wsRef.current = new WebSocket(
      `${IS_TESTNET ? SOLBARN_TESTNET_WSS_URL : SOLBARN_WSS_URL}/markets/ws`
    )

    wsRef.current.onmessage = (event) => {
      const newMarket: WSMarketData = {
        ...JSON.parse(event.data),
        timestamp: Date.now()
      }

      setLiveMarkets((prevMarkets) => {
        const exists = prevMarkets.some((market) => market.id === newMarket.id)
        if (exists) return prevMarkets
        return [newMarket, ...prevMarkets]
      })
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close()
      }
    }
  }, [isEnabled])

  const filteredMarkets = useMemo(() => {
    if (!lastPausedTimestamp) return liveMarkets
    return liveMarkets.filter(
      (market) => market.timestamp <= lastPausedTimestamp
    )
  }, [liveMarkets, lastPausedTimestamp])

  const { connection } = useConnection()
  const tokenInfos = useQueries({
    queries: filteredMarkets.map((market) => ({
      queryFn: async () => {
        const tokenMetadata = await getTokenMetadataWithFallback(
          market.base_token_id,
          connection
        )

        if (!tokenMetadata) {
          return undefined
        }

        return {
          address: market.base_token_id,
          decimals: 6,
          name: tokenMetadata.name,
          symbol: tokenMetadata.symbol
        }
      },
      queryKey: ['tokenInfo', market.base_token_id]
    }))
  })

  const markets: Market[] = useMemo(() => {
    return filteredMarkets
      .map((market) => {
        const tokenInfo = tokenInfos.find(
          (info) =>
            info.data?.address.toLowerCase() ===
            market.base_token_id.toLowerCase()
        )
        if (!tokenInfo?.data) {
          return undefined
        }

        // TODO: can we get the price/supply/liquidity from somewhere?
        return {
          baseToken: {
            address: market.base_token_id,
            decimals: 6,
            name: tokenInfo.data?.name,
            priceNative: '0',
            priceUsd: 0,
            symbol: tokenInfo.data?.symbol
          },
          baseTokenPctChange1d: 0,
          baseTokenPctChange1h: 0,
          baseTokenPctChange5m: 0,
          baseTokenPctChange6h: 0,
          chain: 'solana',
          circulatingSupply: 0,
          circulatingSupplyUsd: 0,
          createdAt: new Date(market.market_created_at),
          description: market.description ?? '',
          liquidityUsd: 0,
          logoUrl: getResizedImageUrl(market.icon_url || '') ?? '',
          marketAddress: market.id,
          numberBuys: 0,
          numberSells: 0,
          quoteToken: {
            address: market.quote_token_id,
            decimals: 9,
            name: 'Wrapped SOL',
            priceNative: '1',
            priceUsd: 0,
            symbol: 'SOL'
          },
          stakingApr: 0,
          stakingPct: 0,
          vestingPct: 0,
          volumeUsd: 0
        } satisfies Market
      })
      .filter(Boolean) as Market[]
  }, [filteredMarkets, tokenInfos])

  const pausedMarketsCount = useMemo(() => {
    if (!lastPausedTimestamp) return 0
    return liveMarkets.filter(
      (market) => market.timestamp > lastPausedTimestamp
    ).length
  }, [liveMarkets, lastPausedTimestamp])

  return {
    data: markets,
    pausedMarketsCount
  }
}

export default useLiveMarketsSolana
