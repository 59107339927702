import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet, useSolbarnGet } from 'hooks/useDexbarn'
import { Chain, MarketData } from 'types/dexbarn'
import { SolbarnMarket } from 'types/solbarn'
import { useDebounce } from 'use-debounce'

interface UseSearchMarketsProps {
  query: string
}

const useSearchMarkets = ({ query }: UseSearchMarketsProps) => {
  const [debouncedQuery] = useDebounce(query, 300)

  const fetchMarkets = useDexbarnGet<MarketData[]>(
    `/v1/markets/search?query=${debouncedQuery}&chain=avalanche&excludeLowVolumeMarkets=false&orderBy=trending&filterBy=1d`
  )

  const fetchMarketsSolana = useSolbarnGet<SolbarnMarket[]>(
    `/v1/markets/search?query=${debouncedQuery}&exclude_low_volume_markets=false&order_by=trending&filter_by=d1`
  )

  return useQuery({
    enabled: debouncedQuery.length > 2,
    queryFn: async () => {
      const [marketsEVM, marketsSolana] = await Promise.all([
        fetchMarkets(),
        fetchMarketsSolana()
      ])

      return [
        ...marketsEVM,
        ...marketsSolana.map(
          (market) =>
            ({
              baseMarketCapUsd: market.base_market_cap_usd,
              baseToken: {
                address: market.base_token.address,
                decimals: market.base_token.decimals,
                name: market.base_token.name,
                priceNative: market.base_token.price_native.toString(),
                priceUsd: market.base_token.price_usd,
                symbol: market.base_token.symbol
              },
              baseTokenPctChange: market.base_token_pct_change,
              baseTokenPctChange1d: market.base_token_pct_change_1d,
              baseTokenPctChange1h: market.base_token_pct_change_1h,
              baseTokenPctChange5m: market.base_token_pct_change_5m,
              baseTokenPctChange6h: market.base_token_pct_change_6h,
              chain: 'solana' as Chain,
              circulatingSupply: market.circulating_supply,
              creatorShare: market.creator_share,
              description: market.description,
              discordUrl: market.discord_url,
              iconUrl: market.icon_url,
              marketAddress: market.market_address,
              marketCreatedAt: market.market_created_at,
              name: market.name,
              numberAsks: market.number_asks,
              numberBids: market.number_bids,
              numberBuyers: market.number_buyers,
              numberSellers: market.number_sellers,
              numberSwaps: market.number_swaps,
              protocolShare:
                10000 - market.creator_share + market.staking_share,
              quoteLiquidityNative: market.quote_liquidity_native,
              quoteLiquidityUsd: market.quote_liquidity_usd,
              quoteToken: {
                address: market.quote_token.address,
                decimals: market.quote_token.decimals,
                name: market.quote_token.name,
                priceNative: market.quote_token.price_native.toString(),
                priceUsd: market.quote_token.price_usd,
                symbol: market.quote_token.symbol
              },
              referrerFeesUsd: market.referrer_fees_usd,
              reserveBase: market.reserve_base,
              reserveQuote: market.reserve_quote,
              stakingApr: market.staking_apr,
              stakingPct: market.staking_pct,
              stakingShare: market.staking_share,
              swapFeesUsd: market.swap_fees_usd,
              telegramUrl: market.telegram_url,
              twitterUrl: market.twitter_url,
              vestingPct: market.vesting_pct,
              volumeAskNative: market.volume_ask_native.toString(),
              volumeAskUsd: market.volume_ask_usd,
              volumeBidNative: market.volume_bid_native.toString(),
              volumeBidUsd: market.volume_bid_usd,
              volumeNative: market.volume_native.toString(),
              volumeUsd: market.volume_usd,
              websiteUrl: market.website_url
            }) satisfies MarketData
        )
      ].sort((a, b) => b.baseMarketCapUsd - a.baseMarketCapUsd)
    },
    queryKey: ['SearchMarkets', debouncedQuery]
  })
}

export default useSearchMarkets
