import { t } from '@lingui/macro'
import { TokenMillFactoryAbi } from 'constants/abi/tokenMillFactory'
import { TM_FACTORY_ADDRESS } from 'constants/addresses'
import { WNATIVE } from 'constants/token'
import useGetReferrerAddressOnChain from 'hooks/referrals/useGetReferrerAddress'
import { CreateMarketAndTokenArgs } from 'hooks/tokenmill/useCreateMarketAndToken'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { getChainId } from 'utils/chains'
import {
  getAddress,
  Hex,
  isAddress,
  parseEventLogs,
  parseUnits,
  zeroAddress
} from 'viem'
import {
  useAccount,
  usePublicClient,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

enum TokenType {
  Invalid = 0,
  BasicERC20 = 1
}

const useCreateMarketAndTokenEVM = ({
  askPrices,
  bidPrices,
  chain,
  creatorFeeShareBps,
  decimals,
  enabled = true,
  name,
  stakingFeeShareBps,
  symbol,
  totalSupply,
  vestingArgs
}: CreateMarketAndTokenArgs) => {
  const chainId = getChainId(chain)
  const client = usePublicClient({ chainId })
  const { address: account, chain: walletChain } = useAccount()
  const addTransactionToast = useTransactionToast()

  const quoteToken = isAddress(WNATIVE[chainId].address)
    ? getAddress(WNATIVE[chainId].address)
    : undefined

  const { data: referrerAddress } = useGetReferrerAddressOnChain({
    chain,
    enabled
  })
  const isSelfReferral =
    referrerAddress?.toLowerCase() === account?.toLowerCase()

  const createMarketArgs = useMemo(() => {
    if (
      totalSupply &&
      decimals &&
      quoteToken &&
      creatorFeeShareBps !== undefined &&
      stakingFeeShareBps !== undefined
    ) {
      return {
        args: ('0x' + decimals.toString(16).padStart(64, '0')) as Hex,
        askPrices: askPrices.map((price) =>
          parseUnits(price.toFixed(decimals), decimals)
        ),
        bidPrices: bidPrices.map((price) =>
          parseUnits(price.toFixed(decimals), decimals)
        ),
        creatorShare: creatorFeeShareBps,
        name,
        quoteToken,
        stakingShare: stakingFeeShareBps,
        symbol,
        tokenType: BigInt(TokenType.BasicERC20),
        totalSupply: BigInt(totalSupply) * BigInt(10) ** BigInt(decimals)
      } as const
    }
    return undefined
  }, [
    decimals,
    askPrices,
    bidPrices,
    creatorFeeShareBps,
    stakingFeeShareBps,
    symbol,
    totalSupply,
    quoteToken,
    name
  ])

  const createVestingArgs = useMemo(() => {
    if (vestingArgs && account) {
      return {
        beneficiary: account,
        cliffDuration: BigInt(vestingArgs.cliffDuration),
        endDuration: BigInt(vestingArgs.vestingDuration),
        percent: BigInt(10000),
        start: BigInt(vestingArgs.start)
      } as const
    }
    return undefined
  }, [vestingArgs, account])

  const createMarketAndTokenConfig = useSimulateContract({
    abi: TokenMillFactoryAbi,
    address: TM_FACTORY_ADDRESS[chainId],
    args: createMarketArgs ? [createMarketArgs] : undefined,
    chainId,
    functionName: 'createMarketAndToken',
    query: {
      enabled:
        enabled && walletChain?.id === chainId && !!account && !vestingArgs,
      gcTime: 0
    }
  })

  const createMarketAndVestingsConfig = useSimulateContract({
    abi: TokenMillFactoryAbi,
    address: TM_FACTORY_ADDRESS[chainId],
    args:
      createMarketArgs && createVestingArgs && vestingArgs
        ? [
            createMarketArgs,
            [createVestingArgs],
            referrerAddress && !isSelfReferral
              ? getAddress(referrerAddress)
              : zeroAddress,
            vestingArgs.quoteAmount,
            vestingArgs.baseAmount
          ]
        : undefined,
    chainId,
    functionName: 'createMarketAndVestings',
    query: {
      enabled:
        enabled && walletChain?.id === chainId && !!account && !!vestingArgs,
      gcTime: 0
    },
    value: vestingArgs?.quoteAmount
  })

  const { isPending, writeContractAsync } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Created ${symbol} market and token`
        addTransactionToast({
          chain,
          description: transactionSummary,
          hash,
          walletAddress: account || ''
        })
      }
    }
  })

  const createMarketAndTokenAsync = client
    ? async () => {
        let hash: Hex
        if (createMarketAndVestingsConfig.data?.request) {
          hash = await writeContractAsync(
            createMarketAndVestingsConfig.data.request
          )
        } else if (createMarketAndTokenConfig.data?.request) {
          hash = await writeContractAsync(
            createMarketAndTokenConfig.data.request
          )
        } else {
          throw new Error('No config found')
        }

        const receipt = await client.waitForTransactionReceipt({ hash })
        const logs = parseEventLogs({
          abi: TokenMillFactoryAbi,
          eventName: 'MarketCreated',
          logs: receipt.logs
        })

        return {
          baseTokenAddress: logs[0].args.baseToken,
          hash,
          marketAddress: logs[0].args.market
        }
      }
    : undefined

  return {
    createMarketAndTokenAsync,
    error:
      createMarketAndVestingsConfig.error || createMarketAndTokenConfig.error,
    isLoading: isPending,
    isSimulating:
      createMarketAndVestingsConfig.isLoading ||
      createMarketAndTokenConfig.isLoading
  }
}

export default useCreateMarketAndTokenEVM
