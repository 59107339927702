import useGetTopHoldersEVM from 'hooks/evm/useGetTopHolders'
import { useMemo } from 'react'
import useGetTopHoldersSolana from 'solana/hooks/useGetTopHolders'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

interface UseGetTopHoldersProps {
  baseTokenAddress: string
  chain: Chain
  marketAddress: string
}

const useGetTopHolders = ({
  baseTokenAddress,
  chain,
  marketAddress
}: UseGetTopHoldersProps) => {
  const chainType = getChainType(chain)

  const topHoldersEVM = useGetTopHoldersEVM({
    baseTokenAddress,
    chain,
    enabled: chainType === 'evm',
    marketAddress
  })

  const topHoldersSolana = useGetTopHoldersSolana({
    baseTokenAddress,
    enabled: chainType === 'solana',
    marketAddress
  })

  return useMemo(() => {
    switch (chain) {
      case 'avalanche':
        return topHoldersEVM
      case 'solana':
        return topHoldersSolana
    }
  }, [chain, topHoldersEVM, topHoldersSolana])
}

export default useGetTopHolders
