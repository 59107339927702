import { Idl, Program } from '@coral-xyz/anchor'
import { useConnection } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import { UseGetMarketStakingDataProps } from 'hooks/tokenmill/useGetMarketStakingData'
import { useCallback } from 'react'
import TokenMillIdl from 'solana/idl/token_mill.json'
import { MarketStakingData } from 'types/market'
import { formatUnits } from 'viem'

const useGetMarketStakingData = ({
  enabled,
  marketAddress
}: UseGetMarketStakingDataProps) => {
  const { connection } = useConnection()

  const getMarketStaking = useCallback(async () => {
    const market = new PublicKey(marketAddress)
    const program = new Program(TokenMillIdl as Idl, { connection })

    const [marketStaking] = PublicKey.findProgramAddressSync(
      [Buffer.from('market_staking'), market.toBuffer()],
      program.programId
    )

    try {
      const marketStakingAccount = await (
        program.account as any
      ).marketStaking.fetch(marketStaking)
      return marketStakingAccount
    } catch (error) {
      console.error('Error fetching market staking:', error)
      return null
    }
  }, [marketAddress, connection])

  return useQuery({
    enabled,
    queryFn: async (): Promise<MarketStakingData> => {
      try {
        const marketStaking = await getMarketStaking()

        const amountStaked = BigInt(marketStaking.amountStaked)
        const totalAmountVested = BigInt(marketStaking.totalAmountVested)
        return {
          totalLockedAmount: {
            formatted: formatUnits(totalAmountVested, 6),
            value: totalAmountVested
          },
          totalStakedAmount: {
            formatted: formatUnits(amountStaked, 6),
            value: amountStaked
          },
          totalStakedAndLockedAmount: {
            formatted: formatUnits(amountStaked + totalAmountVested, 6),
            value: amountStaked + totalAmountVested
          }
        }
      } catch (error) {
        return {
          totalLockedAmount: undefined,
          totalStakedAmount: undefined,
          totalStakedAndLockedAmount: undefined
        }
      }
    },
    queryKey: ['getMarketStakingData', marketAddress]
  })
}

export default useGetMarketStakingData
