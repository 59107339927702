import { useDexbarnClient, useSolbarnClient } from 'hooks/useDexbarn'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useReferralStorage } from 'state/referral'

const ReferralHandler: React.FC = () => {
  const dexbarnClient = useDexbarnClient()
  const solbarnClient = useSolbarnClient()
  const location = useLocation()
  const { referralState, updateReferralInfo } = useReferralStorage()

  useEffect(() => {
    const handleReferralCodeEVM = async (refcode: string) => {
      const referrerAddress = await dexbarnClient
        .get<string>(`/v1/users/referrer-address/${refcode}`)
        .then((r) => r.data)
        .catch(() => null)

      if (referrerAddress) {
        updateReferralInfo('evm', refcode, referrerAddress)
      }
    }

    const handleReferralCodeSolana = async (refcode: string) => {
      const referrerAddress = await solbarnClient
        .get<string>(`/v1/users/referrer-address/${refcode}`)
        .then((r) => r.data)
        .catch(() => null)

      if (referrerAddress) {
        updateReferralInfo('solana', refcode, referrerAddress)
      }
    }

    const searchParams = new URLSearchParams(location.search)
    const urlReferralCode = searchParams.get('r')

    const isAlreadyReferredOnEVM = !!referralState.evm.code
    const isAlreadyReferredOnSolana = !!referralState.solana.code

    // Only update if there's a code in the URL and no existing code stored
    if (urlReferralCode && !isAlreadyReferredOnEVM) {
      handleReferralCodeEVM(urlReferralCode)
    }
    if (urlReferralCode && !isAlreadyReferredOnSolana) {
      handleReferralCodeSolana(urlReferralCode)
    }
  }, [
    location,
    updateReferralInfo,
    dexbarnClient,
    referralState,
    solbarnClient
  ])

  return null
}

export default ReferralHandler
