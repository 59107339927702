import { useQuery } from '@tanstack/react-query'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import { useDexbarnGet, useSolbarnGet } from 'hooks/useDexbarn'
import { Chain } from 'types/dexbarn'

const useGetUserReferralCodes = () => {
  const { address: addressEVM } = useAccountOnChain('avalanche')
  const { address: addressSolana } = useAccountOnChain('solana')

  const fetchReferrerCodeEVM = useDexbarnGet<string>(
    `/v1/users/referrer-code/${addressEVM}`
  )

  const fetchReferrerCodeSolana = useSolbarnGet<string>(
    `/v1/users/referrer-code/${addressSolana}`
  )

  return useQuery({
    enabled: !!addressEVM,
    queryFn: async () => {
      const chainReferralCodes: { [chain in Chain]: string } = {
        avalanche: '',
        solana: ''
      }

      if (!!addressEVM) {
        chainReferralCodes.avalanche = await fetchReferrerCodeEVM().catch(
          () => ''
        )
      }

      if (!!addressSolana) {
        chainReferralCodes.solana = await fetchReferrerCodeSolana().catch(
          () => ''
        )
      }

      return chainReferralCodes
    },
    queryKey: ['GetReferrerCode', addressEVM, addressSolana]
  })
}

export default useGetUserReferralCodes
