import {
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Image,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  SimpleGrid,
  Skeleton,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { NATIVE_MINT } from '@solana/spl-token'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CopyableError from 'components/CopyableError'
import CurrencyLogo from 'components/CurrencyLogo'
import InputBalanceButton from 'components/InputBalanceButton'
import NumericalInput from 'components/NumericalInput'
import Warning from 'components/Warning'
import Web3Button from 'components/Web3Button'
import useApproveSpenderIfNeeded from 'hooks/evm/useApproveSpenderIfNeeded'
import useSwap from 'hooks/tokenmill/useSwap'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import useTokenBalance from 'hooks/useTokenBalance'
import React, { useMemo, useState } from 'react'
import { Chain } from 'types/dexbarn'
import { Token, TokenBalance } from 'types/token'
import { getChainId } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { formatUnits, zeroAddress } from 'viem'

import { TM_ROUTER } from '../../constants/addresses'

const OptionButton = ({
  onClick,
  value
}: {
  onClick: () => void
  value: string
}) => (
  <Button
    size="sm"
    variant="boxShadowFlat"
    bg="bgSecondary"
    color="textPrimary"
    onClick={onClick}
  >
    {`[${value}]`}
  </Button>
)

enum TokenMillTradePanelTabs {
  BUY,
  SELL
}

interface TokenMillTradePanelProps {
  askPrice: number
  baseToken: Token
  bidPrice: number
  chain: Chain
  isLoadingBaseTokenBalance: boolean
  marketAddress: string
  onBaseTokenBalanceChange: () => void
  quoteCurrency: Token
  baseTokenBalance?: TokenBalance
  baseTokenLogoUrl?: string
}

const TokenMillTradePanel = ({
  askPrice,
  baseToken,
  baseTokenBalance,
  baseTokenLogoUrl,
  bidPrice,
  chain,
  isLoadingBaseTokenBalance,
  marketAddress,
  onBaseTokenBalanceChange,
  quoteCurrency
}: TokenMillTradePanelProps) => {
  const chainId = getChainId(chain)

  const [selectedTab, setSelectedTab] = useState<TokenMillTradePanelTabs>(
    TokenMillTradePanelTabs.BUY
  )

  const currencyIn =
    selectedTab === TokenMillTradePanelTabs.BUY ? quoteCurrency : baseToken
  const currencyOut =
    selectedTab === TokenMillTradePanelTabs.BUY ? baseToken : quoteCurrency

  const {
    amount: amountIn,
    amountBN: amountInBN,
    setAmount: setAmountIn
  } = useCurrencyInputAmount({
    currency: currencyIn
  })
  const [slippagePercent, setSlippagePercent] = useState<string>('0.5')
  const allowedSlippageBps = Number(slippagePercent) * 100

  const {
    isOpen: isBuyConfirmationOpen,
    onClose: onBuyConfirmationClose,
    onOpen: onBuyConfirmationOpen
  } = useDisclosure()
  const currentFeePercent = (1 - bidPrice / askPrice) * 100
  const isHighFee = currentFeePercent >= 10

  const isQuoteWrappedSol = quoteCurrency.address === NATIVE_MINT.toBase58()
  const isQuoteNative = quoteCurrency.address === zeroAddress
  const {
    data: quoteTokenBalance,
    isLoading: isLoadingQuoteTokenBalance,
    refetch: refetchQuoteTokenBalance
  } = useTokenBalance({
    chain,
    token: isQuoteNative || isQuoteWrappedSol ? 'native' : quoteCurrency.address
  })

  const amountInErrorMsg = useMemo(() => {
    if (!amountInBN) return ''

    switch (selectedTab) {
      case TokenMillTradePanelTabs.BUY:
        if (!quoteTokenBalance) return ''
        if (amountInBN > quoteTokenBalance.value) {
          return `Insufficient balance: ${formattedNum(
            quoteTokenBalance.formatted
          )} ${quoteCurrency.symbol}`
        }
        return ''
      case TokenMillTradePanelTabs.SELL:
        if (!baseTokenBalance) return ''
        if (amountInBN > baseTokenBalance.value) {
          return `Insufficient balance: ${baseTokenBalance.formatted} ${baseToken.symbol}`
        }
        return ''
    }
  }, [
    selectedTab,
    amountInBN,
    baseTokenBalance,
    quoteTokenBalance,
    baseToken.symbol,
    quoteCurrency.symbol
  ])

  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    isLoadingAllowance,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: amountInBN,
    chain,
    spender: TM_ROUTER[chainId] || '',
    token: currencyIn.address !== zeroAddress ? currencyIn.address : undefined,
    tokenSymbol: currencyIn.symbol
  })

  const {
    error: swapError,
    isLoadingQuote,
    isSwapping,
    quote,
    swapAsync
  } = useSwap({
    allowedSlippageBps,
    amountIn: amountInBN,
    chain,
    currencyIn,
    currencyOut,
    enabled:
      !amountInErrorMsg &&
      (isApproved || currencyIn.address === zeroAddress || chain === 'solana'),
    marketAddress,
    onSwapSuccess: () => {
      setAmountIn('')
      onBaseTokenBalanceChange()
      refetchQuoteTokenBalance()
      resetApproval()
    }
  })

  const setBaseTokenBalance = (percent: number) => {
    if (!baseTokenBalance) return

    setAmountIn(
      formatUnits(
        (baseTokenBalance.value * BigInt(percent * 100)) / BigInt(100),
        baseToken.decimals
      )
    )
  }

  return (
    <Box w="full" p={{ base: 4, md: 6 }} borderRadius="2xl" h="fit-content">
      <Tabs
        isLazy
        variant="bracketed"
        index={selectedTab}
        onChange={(tab) => {
          setAmountIn('')
          setSelectedTab(tab)
        }}
      >
        <TabList>
          <Tab>Buy</Tab>
          <Tab>Sell</Tab>
        </TabList>
        <TabPanels px={0} pb={0}>
          <TabPanel px={0} py={0}>
            <VStack spacing={2}>
              <Skeleton
                isLoaded={!isLoadingQuoteTokenBalance}
                alignSelf="flex-end"
              >
                <InputBalanceButton
                  balance={quoteTokenBalance?.formatted ?? '0'}
                  onClick={() => {
                    if (!quoteTokenBalance?.formatted) return
                    const balance = Number(quoteTokenBalance.formatted)
                    setAmountIn(Math.max(balance - 0.01, 0).toString())
                  }}
                />
              </Skeleton>
              <InputGroup>
                <NumericalInput
                  placeholder="0"
                  inputType="decimal"
                  w="full"
                  size="lg"
                  value={amountIn}
                  onValueChange={setAmountIn}
                  isInvalid={!!amountInErrorMsg}
                />
                <InputRightElement w="fit-content" h="full" pr={4}>
                  <CurrencyLogo symbol={quoteCurrency.symbol} boxSize={6} />
                </InputRightElement>
              </InputGroup>
              <SimpleGrid columns={6} w="full" spacing={2}>
                <OptionButton value="0.1" onClick={() => setAmountIn('0.1')} />
                <OptionButton
                  value="0.25"
                  onClick={() => setAmountIn('0.25')}
                />
                <OptionButton value="0.5" onClick={() => setAmountIn('0.5')} />
                <OptionButton value="1" onClick={() => setAmountIn('1')} />
                <OptionButton value="5" onClick={() => setAmountIn('5')} />
                <OptionButton value="10" onClick={() => setAmountIn('10')} />
              </SimpleGrid>
            </VStack>

            <VStack spacing={2} mt={4}>
              <InputGroup>
                <NumericalInput
                  placeholder="0"
                  w="full"
                  size="lg"
                  inputType="decimal"
                  value={slippagePercent}
                  onValueChange={setSlippagePercent}
                />
                <InputRightElement w="fit-content" h="full" pr={4}>
                  <Text textColor="textSecondary" fontSize="sm">
                    Slippage %
                  </Text>
                </InputRightElement>
              </InputGroup>
              <SimpleGrid columns={6} w="full" spacing={2}>
                <OptionButton
                  value="0.1%"
                  onClick={() => setSlippagePercent('0.1')}
                />
                <OptionButton
                  value="0.5%"
                  onClick={() => setSlippagePercent('0.5')}
                />
                <OptionButton
                  value="1%"
                  onClick={() => setSlippagePercent('1')}
                />
                <OptionButton
                  value="2%"
                  onClick={() => setSlippagePercent('2')}
                />
                <OptionButton
                  value="5%"
                  onClick={() => setSlippagePercent('5')}
                />
                <OptionButton
                  value="10%"
                  onClick={() => setSlippagePercent('10')}
                />
              </SimpleGrid>
            </VStack>

            <VStack spacing={2} mt={8} fontSize="sm">
              <HStack
                w="full"
                borderBottom="1px solid"
                borderBottomColor="border"
                borderBottomStyle="dashed"
              >
                <Text textColor="textSecondary">Expected Output:</Text>
                <Spacer />
                <Skeleton isLoaded={!isLoadingQuote} minW="100px">
                  {quote ? (
                    <Text fontWeight="semibold" textAlign="right">
                      {formattedNum(quote.amountOut.formatted)}{' '}
                      {quote.currencyOut.symbol}
                    </Text>
                  ) : (
                    <Text fontWeight="semibold" textAlign="right">
                      --
                    </Text>
                  )}
                </Skeleton>
              </HStack>

              <HStack
                w="full"
                borderBottom="1px solid"
                borderBottomColor="border"
                borderBottomStyle="dashed"
              >
                <Text textColor="textSecondary">Minimum Received:</Text>
                <Spacer />
                <Skeleton isLoaded={!isLoadingQuote} minW="100px">
                  {quote ? (
                    <Text fontWeight="semibold" textAlign="right">
                      {formattedNum(quote.minAmountOut.formatted)}{' '}
                      {quote.currencyOut.symbol}
                    </Text>
                  ) : (
                    <Text fontWeight="semibold" textAlign="right">
                      --
                    </Text>
                  )}
                </Skeleton>
              </HStack>
            </VStack>

            <VStack spacing={2} mt={8}>
              {isHighFee ? (
                <Warning
                  text={`This token has a fee of ${formattedNum(
                    currentFeePercent,
                    { places: 2 }
                  )}% when you sell it later.`}
                />
              ) : null}

              {isHighFee ? (
                <Popover
                  isOpen={isBuyConfirmationOpen}
                  onClose={onBuyConfirmationClose}
                >
                  <PopoverTrigger>
                    <Web3Button
                      chain={chain}
                      size="lg"
                      variant="boxShadowFlat"
                      textColor="white"
                      bg="green.400"
                      colorScheme="green"
                      w="full"
                      isDisabled={!swapAsync || !quote}
                      isLoading={isSwapping || isLoadingQuote}
                      loadingText={
                        isLoadingQuote
                          ? 'Calculating quote'
                          : 'Waiting for confirmation'
                      }
                      onClick={onBuyConfirmationOpen}
                    >
                      {quote
                        ? `[Buy ${
                            quote ? formattedNum(quote.amountOut.formatted) : ''
                          } ${baseToken.symbol}]`
                        : `[Buy ${baseToken.symbol}]`}
                    </Web3Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      Are you sure you want to buy this token?
                    </PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button
                          variant="outline"
                          onClick={onBuyConfirmationClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            swapAsync?.()
                            onBuyConfirmationClose()
                          }}
                        >
                          Buy Anyway
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              ) : (
                <Web3Button
                  chain={chain}
                  size="lg"
                  variant="boxShadowFlat"
                  textColor="white"
                  bg="green.400"
                  colorScheme="green"
                  w="full"
                  isDisabled={!swapAsync || !quote}
                  isLoading={isSwapping || isLoadingQuote}
                  loadingText={
                    isLoadingQuote
                      ? 'Calculating quote'
                      : 'Waiting for confirmation'
                  }
                  onClick={() => swapAsync?.()}
                >
                  {quote
                    ? `[Buy ${
                        quote ? formattedNum(quote.amountOut.formatted) : ''
                      } ${baseToken.symbol}]`
                    : `[Buy ${baseToken.symbol}]`}
                </Web3Button>
              )}
            </VStack>

            {amountInErrorMsg ? (
              <Center mt={4}>
                <Text textColor="red.500" fontSize="sm">
                  {amountInErrorMsg}
                </Text>
              </Center>
            ) : swapError ? (
              <CopyableError
                error={swapError.message}
                summary={swapError.summary}
                textProps={{ mt: 2 }}
              />
            ) : null}
          </TabPanel>

          <TabPanel px={0} py={0}>
            <VStack spacing={2}>
              <Skeleton
                isLoaded={!isLoadingBaseTokenBalance}
                alignSelf="flex-end"
              >
                <InputBalanceButton
                  balance={baseTokenBalance?.formatted ?? '0'}
                  onClick={() =>
                    setAmountIn(baseTokenBalance?.formatted ?? '0')
                  }
                />
              </Skeleton>

              <InputGroup>
                <NumericalInput
                  placeholder="0"
                  w="full"
                  size="lg"
                  value={amountIn}
                  onValueChange={setAmountIn}
                />
                <InputRightElement w="fit-content" h="full" pr={4}>
                  <Image
                    src={baseTokenLogoUrl}
                    boxSize={6}
                    objectFit="contain"
                  />
                </InputRightElement>
              </InputGroup>
              <SimpleGrid columns={6} w="full" spacing={2}>
                <OptionButton
                  value="25%"
                  onClick={() => setBaseTokenBalance(0.25)}
                />
                <OptionButton
                  value="50%"
                  onClick={() => setBaseTokenBalance(0.5)}
                />
                <OptionButton
                  value="75%"
                  onClick={() => setBaseTokenBalance(0.75)}
                />
                <OptionButton
                  value="100%"
                  onClick={() => setBaseTokenBalance(1)}
                />
              </SimpleGrid>
            </VStack>

            <VStack spacing={2} mt={4}>
              <InputGroup>
                <NumericalInput
                  placeholder="0"
                  w="full"
                  size="lg"
                  inputType="decimal"
                  value={slippagePercent}
                  onValueChange={setSlippagePercent}
                />
                <InputRightElement w="fit-content" h="full" pr={4}>
                  <Text textColor="textSecondary" fontSize="sm">
                    Slippage %
                  </Text>
                </InputRightElement>
              </InputGroup>
              <SimpleGrid columns={6} w="full" spacing={2}>
                <OptionButton
                  value="5%"
                  onClick={() => setSlippagePercent('5')}
                />
                <OptionButton
                  value="10%"
                  onClick={() => setSlippagePercent('10')}
                />
                <OptionButton
                  value="15%"
                  onClick={() => setSlippagePercent('15')}
                />
                <OptionButton
                  value="20%"
                  onClick={() => setSlippagePercent('20')}
                />
                <OptionButton
                  value="25%"
                  onClick={() => setSlippagePercent('25')}
                />
                <OptionButton
                  value="50%"
                  onClick={() => setSlippagePercent('50')}
                />
              </SimpleGrid>
            </VStack>

            <VStack spacing={2} mt={8} fontSize="sm">
              <HStack
                w="full"
                borderBottom="1px solid"
                borderBottomColor="border"
                borderBottomStyle="dashed"
              >
                <Text textColor="textSecondary">Expected Output:</Text>
                <Spacer />
                <Skeleton isLoaded={!isLoadingQuote} minW="100px">
                  {quote ? (
                    <Text fontWeight="semibold" textAlign="right">
                      {formattedNum(quote.amountOut.formatted)}{' '}
                      {quote.currencyOut.symbol}
                    </Text>
                  ) : (
                    <Text fontWeight="semibold" textAlign="right">
                      --
                    </Text>
                  )}
                </Skeleton>
              </HStack>

              <HStack
                w="full"
                borderBottom="1px solid"
                borderBottomColor="border"
                borderBottomStyle="dashed"
              >
                <Text textColor="textSecondary">Minimum Received:</Text>
                <Spacer />
                <Skeleton isLoaded={!isLoadingQuote} minW="100px">
                  {quote ? (
                    <Text fontWeight="semibold" textAlign="right">
                      {formattedNum(quote.minAmountOut.formatted)}{' '}
                      {quote.currencyOut.symbol}
                    </Text>
                  ) : (
                    <Text fontWeight="semibold" textAlign="right">
                      --
                    </Text>
                  )}
                </Skeleton>
              </HStack>
            </VStack>

            <VStack mt={8} spacing={4}>
              {!isApproved && approve && !amountInErrorMsg ? (
                <ApproveTokenButton
                  amount={amountIn}
                  approvalType={approvalType}
                  onApprovalTypeSelect={setApprovalType}
                  currencySymbol={currencyIn.symbol}
                  onClick={approve}
                  isLoading={isLoadingAllowance || isApproving}
                />
              ) : null}

              <Web3Button
                chain={chain}
                size="lg"
                variant="boxShadowFlat"
                bg="red.500"
                colorScheme="red"
                textColor="white"
                w="full"
                isDisabled={!swapAsync || !quote}
                isLoading={isSwapping || isLoadingQuote}
                loadingText={
                  isLoadingQuote
                    ? 'Calculating quote'
                    : 'Waiting for confirmation'
                }
                onClick={() => swapAsync?.()}
              >
                {quote
                  ? `[Sell ${formattedNum(quote.amountIn.formatted)}] ${
                      baseToken.symbol
                    }`
                  : `[Sell ${baseToken.symbol}]`}
              </Web3Button>

              {amountInErrorMsg ? (
                <Center>
                  <Text textColor="red.500" fontSize="sm">
                    {amountInErrorMsg}
                  </Text>
                </Center>
              ) : swapError ? (
                <CopyableError
                  error={swapError.message}
                  summary={swapError.summary}
                  textProps={{ mt: 2 }}
                />
              ) : null}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default TokenMillTradePanel
