import { useQuery } from '@tanstack/react-query'
import { TokenMillLensAbi } from 'constants/abi/tokenMillLens'
import { TM_LENS } from 'constants/addresses'
import { useDexbarnGet } from 'hooks/useDexbarn'
import qs from 'qs'
import { Chain, MarketDataToken } from 'types/dexbarn'
import {
  PortfolioStakingPosition,
  PortfolioVestingPosition
} from 'types/portfolio'
import { getChainId } from 'utils/chains'
import { formatUnits, getAddress } from 'viem'
import { usePublicClient } from 'wagmi'

interface TokenInfo extends MarketDataToken {
  iconUrl: string
}

interface UseGetUserStakingAndVestingPositionsProps {
  chain: Chain
  userAddress?: string
}

const useGetUserStakingAndVestingPositions = ({
  chain,
  userAddress
}: UseGetUserStakingAndVestingPositionsProps) => {
  const chainId = getChainId(chain)

  const publicClient = usePublicClient({ chainId })

  const fetchTokensInfo = useDexbarnGet<TokenInfo[]>(
    `/v1/tokens/token-info/${chain}`
  )

  return useQuery<{
    stakingPositions: PortfolioStakingPosition[]
    vestingPositions: PortfolioVestingPosition[]
  }>({
    queryFn: async () => {
      const lensAddress = TM_LENS[chainId]

      if (!publicClient || !userAddress || !lensAddress) {
        return {
          stakingPositions: [],
          vestingPositions: []
        }
      }

      const start = BigInt(0)
      const offset = BigInt(100)

      const lensData = await publicClient.readContract({
        abi: TokenMillLensAbi,
        address: lensAddress,
        args: [getAddress(userAddress), start, offset, start, offset],
        functionName: 'getMultipleDetailedStakingDataPerUser'
      })

      const tokens = lensData.map((token) => token.baseToken.toLowerCase())

      const tokensInfo = await fetchTokensInfo({
        params: { tokens },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat' })
      })

      const stakingPositions = lensData
        .map((position) => {
          const tokenInfo = tokensInfo.find(
            (token) =>
              token.address.toLowerCase() === position.baseToken.toLowerCase()
          )

          const amountStaked = Number(formatUnits(position.sharesAmount, 18))

          return {
            amountStaked,
            baseTokenLogoUrl: tokenInfo?.iconUrl || '',
            baseTokenPriceUsd: tokenInfo?.priceUsd || 0,
            baseTokenSymbol: position.baseTokenSymbol,
            chain,
            marketAddress: position.market,
            valueUsd: amountStaked * (tokenInfo?.priceUsd || 0)
          }
        })
        .filter((position) => position.amountStaked > 0)

      const vestingPositions = lensData
        .map((position) => {
          return position.vestingSchedules.map((vestingSchedule) => {
            const tokenInfo = tokensInfo.find(
              (token) =>
                token.address.toLowerCase() === position.baseToken.toLowerCase()
            )

            const amountReleased = Number(
              formatUnits(vestingSchedule.released, 18)
            )
            const amountVesting = Number(formatUnits(vestingSchedule.total, 18))

            return {
              amountReleased,
              baseTokenLogoUrl: tokenInfo?.iconUrl || '',
              baseTokenPriceUsd: tokenInfo?.priceUsd || 0,
              baseTokenSymbol: position.baseTokenSymbol,
              chain,
              cliffDuration: Number(vestingSchedule.cliffDuration),
              marketAddress: position.market,
              start: Number(vestingSchedule.start),
              valueUsd:
                (amountVesting - amountReleased) * (tokenInfo?.priceUsd || 0),
              vestingAmount: amountVesting,
              vestingDuration: Number(vestingSchedule.vestingDuration)
            }
          })
        })
        .flat()

      return {
        stakingPositions,
        vestingPositions
      }
    },
    queryKey: ['GetUserStakingAndVestingPositions', chain, userAddress]
  })
}

export default useGetUserStakingAndVestingPositions
