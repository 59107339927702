import { bs58 } from '@coral-xyz/anchor/dist/cjs/utils/bytes'
import {
  ComputeBudgetProgram,
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  TransactionInstruction,
  TransactionMessage,
  VersionedTransaction
} from '@solana/web3.js'
import { DEFAULT_MAX_TRANSACTION_FEE, FeeMode } from 'state/settings/reducer'

const calculatePriorityFee = async (
  connection: Connection,
  instructions: TransactionInstruction[],
  payerKey: PublicKey,
  priorityLevel: 'Low' | 'High' | 'VeryHigh'
) => {
  // Get the latest blockhash for simulation
  const latestBlockhash = await connection.getLatestBlockhash()

  // Create a temporary transaction for fee estimation
  const simulationMessage = new TransactionMessage({
    instructions,
    payerKey,
    recentBlockhash: latestBlockhash.blockhash
  }).compileToV0Message()

  const simulationTransaction = new VersionedTransaction(simulationMessage)
  const serializedSimulationTransaction = bs58.encode(
    simulationTransaction.serialize()
  )

  const maxRetries = 5
  let currentTry = 0

  while (currentTry < maxRetries) {
    try {
      const response = await fetch(connection.rpcEndpoint, {
        body: JSON.stringify({
          id: '1',
          jsonrpc: '2.0',
          method: 'getPriorityFeeEstimate',
          params: [
            {
              options: {
                priorityLevel
              },
              transaction: serializedSimulationTransaction
            }
          ]
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST'
      })

      const data = await response.json()
      const priorityFeeEstimate = data.result?.priorityFeeEstimate

      if (!priorityFeeEstimate) {
        throw new Error('No priority fee estimate received')
      }
      return Math.trunc(priorityFeeEstimate)
    } catch (err) {
      currentTry++

      if (currentTry === maxRetries) {
        return null
      }

      // Wait before retrying with exponential backoff
      await new Promise((resolve) => setTimeout(resolve, 250))
    }
  }

  return null
}

const getSimulationComputeUnits = async (
  connection: Connection,
  instructions: TransactionInstruction[],
  walletPublicKey: PublicKey
) => {
  const testInstructions = [
    ComputeBudgetProgram.setComputeUnitLimit({ units: 1_400_000 }),
    ...instructions
  ]

  const testTransaction = new VersionedTransaction(
    new TransactionMessage({
      instructions: testInstructions,
      payerKey: walletPublicKey,
      recentBlockhash: PublicKey.default.toString()
    }).compileToV0Message()
  )

  const rpcResponse = await connection.simulateTransaction(testTransaction, {
    replaceRecentBlockhash: true,
    sigVerify: false
  })

  return rpcResponse.value.unitsConsumed || null
}

export const buildTransactionWithPriorityFee = async (
  connection: Connection,
  instructions: TransactionInstruction[],
  walletPublicKey: PublicKey,
  maxPriorityFeeInSOL: string | undefined,
  feeMode: FeeMode,
  options: {
    addJitoTip?: boolean
    computeUnitLimit?: number
  } = {}
) => {
  const { addJitoTip = false, computeUnitLimit } = options

  const priorityFeeInSOL = parseFloat(
    maxPriorityFeeInSOL || DEFAULT_MAX_TRANSACTION_FEE
  )

  let microLamports: number
  let units: number
  let latestBlockhash: Readonly<{
    blockhash: string
    lastValidBlockHeight: number
  }>

  switch (feeMode) {
    case 'maxCap': {
      const [highPriorityFee, unitsConsumed, blockhash] = await Promise.all([
        calculatePriorityFee(connection, instructions, walletPublicKey, 'High'),
        getSimulationComputeUnits(connection, instructions, walletPublicKey),
        connection.getLatestBlockhash()
      ])

      const unitLimit = computeUnitLimit
        ? computeUnitLimit
        : Math.trunc(unitsConsumed ? unitsConsumed * 1.2 : 200_000)

      const maxPriorityFeeMicroLamports = Math.trunc(
        (priorityFeeInSOL * LAMPORTS_PER_SOL * 1_000_000) / unitLimit
      )
      const isAboveMaxPriorityFee = highPriorityFee
        ? highPriorityFee > maxPriorityFeeMicroLamports
        : false

      const actualPriorityFeeMicroLamports =
        !isAboveMaxPriorityFee && highPriorityFee
          ? highPriorityFee
          : maxPriorityFeeMicroLamports

      microLamports = actualPriorityFeeMicroLamports
      units = unitLimit
      latestBlockhash = blockhash

      break
    }
    case 'exactFee': {
      const [unitsConsumed, blockhash] = await Promise.all([
        getSimulationComputeUnits(connection, instructions, walletPublicKey),
        connection.getLatestBlockhash()
      ])

      const unitLimit = computeUnitLimit
        ? computeUnitLimit
        : Math.trunc(unitsConsumed ? unitsConsumed * 1.2 : 200_000)

      const exactPriorityFeeMicroLamports = Math.trunc(
        (priorityFeeInSOL * LAMPORTS_PER_SOL * 1_000_000) / unitLimit
      )

      microLamports = exactPriorityFeeMicroLamports
      units = unitLimit
      latestBlockhash = blockhash

      break
    }
  }

  // Set the compute unit price
  instructions.unshift(
    ComputeBudgetProgram.setComputeUnitPrice({
      microLamports
    })
  )

  // Set the compute unit limit
  instructions.unshift(
    ComputeBudgetProgram.setComputeUnitLimit({
      units
    })
  )

  if (addJitoTip) {
    const jitoTipFloor = 0.0001
    const jitoTipFloorMicroLamports = Math.trunc(
      jitoTipFloor * LAMPORTS_PER_SOL
    )
    instructions.push(
      SystemProgram.transfer({
        fromPubkey: walletPublicKey,
        lamports: jitoTipFloorMicroLamports,
        toPubkey: new PublicKey('ADaUMid9yfUytqMBgopwjb2DTLSokTSzL1zt6iGPaS49')
      })
    )
  }

  // Create a new transaction message with all instructions including compute budget
  const messageV0 = new TransactionMessage({
    instructions,
    payerKey: walletPublicKey,
    recentBlockhash: latestBlockhash.blockhash
  }).compileToV0Message()

  // Create a new VersionedTransaction
  const transaction = new VersionedTransaction(messageV0)

  return { latestBlockhash, transaction }
}

export const sendJitoBundle = async (transactions: VersionedTransaction[]) => {
  const encodedTransactions = transactions.map((txn) =>
    bs58.encode(txn.serialize())
  )

  const response = await fetch(
    'https://mainnet.block-engine.jito.wtf/api/v1/bundles',
    {
      body: JSON.stringify({
        id: 1,
        jsonrpc: '2.0',
        method: 'sendBundle',
        params: [encodedTransactions]
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    }
  )

  if (!response.ok) {
    throw new Error(`Failed to send bundle: ${response.statusText}`)
  }

  return response.json()
}
