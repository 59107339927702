import { Text } from '@chakra-ui/react'
import React from 'react'
import { formattedNum } from 'utils/format'

const PriceChangeColumn = ({ value }: { value: number }) => {
  if (value === 0) {
    return (
      <Text textColor="textPrimary">{formattedNum(value, { places: 2 })}%</Text>
    )
  }
  if (value > 0) {
    return (
      <Text textColor="green.400">+{formattedNum(value, { places: 2 })}%</Text>
    )
  }
  return <Text textColor="red.500">{formattedNum(value, { places: 2 })}%</Text>
}

export default PriceChangeColumn
