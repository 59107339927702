import { coinbaseWallet, walletConnect } from '@wagmi/connectors'
import { fallback, http } from 'viem'
import { createConfig } from 'wagmi'

import { chains, TokenMillChainId } from './chains'

const poktPortalId = process.env.REACT_APP_POKT_PORTAL_ID ?? ''
const walletConnectProjectId =
  process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? ''

export const wagmiConfig = createConfig({
  batch: { multicall: true },
  chains,
  connectors: [
    walletConnect({ projectId: walletConnectProjectId }),
    coinbaseWallet({ appName: 'Token Mill' })
  ],
  transports: {
    [TokenMillChainId.AVALANCHE]: fallback([
      http(`https://avax-mainnet.rpc.grove.city/v1/${poktPortalId}`),
      http()
    ]),
    [TokenMillChainId.FUJI]: http()
  }
})
