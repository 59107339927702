import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient, useSolbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import { AuthTokens } from 'types/dexbarn'
import { Chain } from 'types/dexbarn'
import { SolbarnAuthTokens } from 'types/solbarn'

const useRefreshToken = ({
  chain
}: {
  chain: Chain
}): ((refreshToken: string) => Promise<AuthTokens>) => {
  const dexbarnClient = useDexbarnClient()
  const solbarnClient = useSolbarnClient()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (refreshToken: string): Promise<AuthTokens> => {
      try {
        switch (chain) {
          case 'avalanche': {
            const response = await dexbarnClient.get<AuthTokens>(
              `/v1/auth/refresh-token`,
              {
                headers: { Authorization: `Bearer ${refreshToken}` }
              }
            )
            return response.data
          }
          case 'solana': {
            const response = await solbarnClient.get<SolbarnAuthTokens>(
              `/v1/auth/refresh-token`,
              {
                headers: { Authorization: `Bearer ${refreshToken}` }
              }
            )
            return {
              accessToken: response.data.access_token,
              refreshToken: response.data.refresh_token
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to refresh auth token'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [dexbarnClient, solbarnClient, addErrorPopup, chain]
  )
}

export default useRefreshToken
