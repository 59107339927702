import useGetMarketVestingsFromSolbarn from 'hooks/barn/solana/useGetMarketVestingsFromSolbarn'
import useGetVestingDataEVM from 'hooks/evm/useGetVestingData'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseGetVestingDataProps {
  chain: Chain
  enabled: boolean
  marketAddress: string
  baseTokenAddress?: string
}

const useGetVestingData = (props: Omit<UseGetVestingDataProps, 'enabled'>) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const marketVestingDataEVM = useGetVestingDataEVM({
    ...props,
    enabled: chainType === 'evm'
  })

  const marketVestingDataSolana = useGetMarketVestingsFromSolbarn({
    ...props,
    enabled: chainType === 'solana'
  })

  switch (chainType) {
    case 'evm':
      return {
        ...marketVestingDataEVM,
        data: marketVestingDataEVM.data?.vestingSchedules
      }
    case 'solana':
      return {
        ...marketVestingDataSolana,
        data: marketVestingDataSolana.vestings
      }
  }
}

export default useGetVestingData
