import { useInfiniteQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { Chain, TMMarketQueryParam } from 'types/dexbarn'
import { Market } from 'types/market'
import { SolbarnMarket, SolbarnMarketQueryParam } from 'types/solbarn'
import { getResizedImageUrl } from 'utils/image'
import { convertToSolbarnFilterBy } from 'utils/solbarn'

export interface UseGetSolanaMarketsParams {
  enabled?: boolean
  excludeLowVolumeMarkets?: boolean
  filterBy?: TMMarketQueryParam.FilterBy
  isDesc?: boolean
  orderBy?: TMMarketQueryParam.OrderBy
  pageSize?: number
}

const useGetMarketsFromSolbarn = ({
  enabled,
  excludeLowVolumeMarkets,
  filterBy,
  isDesc,
  orderBy = 'new',
  pageSize = 20
}: UseGetSolanaMarketsParams = {}) => {
  const fetchMarkets = useSolbarnGet<SolbarnMarket[]>('/v1/markets')

  const solbarnOrderBy: SolbarnMarketQueryParam.OrderBy = orderBy

  return useInfiniteQuery<Market[]>({
    enabled,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchMarkets({
        params: {
          exclude_low_volume_markets: excludeLowVolumeMarkets,
          filter_by: filterBy ? convertToSolbarnFilterBy(filterBy) : null,
          is_desc: isDesc,
          order_by: solbarnOrderBy,
          page_num: pageParam,
          page_size: pageSize
        }
      })
      return data.map((market) => {
        return {
          baseToken: {
            address: market.base_token.address,
            decimals: market.base_token.decimals,
            name: market.base_token.name,
            priceNative: market.base_token.price_native.toString(),
            priceUsd: market.base_token.price_usd,
            symbol: market.base_token.symbol
          },
          baseTokenPctChange1d: market.base_token_pct_change_1d,
          baseTokenPctChange1h: market.base_token_pct_change_1h,
          baseTokenPctChange5m: market.base_token_pct_change_5m,
          baseTokenPctChange6h: market.base_token_pct_change_6h,
          chain: 'solana' as Chain,
          circulatingSupply: market.circulating_supply,
          circulatingSupplyUsd: market.base_market_cap_usd,
          createdAt: new Date(market.market_created_at),
          description: market.description,
          liquidityUsd: market.quote_liquidity_usd,
          logoUrl: getResizedImageUrl(market.icon_url) || '',
          marketAddress: market.market_address,
          numberBuys: market.number_bids,
          numberSells: market.number_asks,
          quoteToken: {
            address: market.quote_token.address,
            decimals: market.quote_token.decimals,
            name: market.quote_token.name,
            priceNative: market.quote_token.price_native.toString(),
            priceUsd: market.quote_token.price_usd,
            symbol: market.quote_token.symbol.slice(0, 6)
          },
          stakingApr: market.staking_apr,
          stakingPct: market.staking_pct,
          vestingPct: market.vesting_pct,
          volumeUsd: market.volume_usd
        } satisfies Market
      })
    },
    queryKey: [
      'GetSolanaMarkets',
      pageSize,
      orderBy,
      filterBy,
      excludeLowVolumeMarkets,
      isDesc
    ]
  })
}

export default useGetMarketsFromSolbarn
