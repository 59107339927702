import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { SolbarnMarket, SolbarnMarketQueryParam } from 'types/solbarn'

interface UseGetMarketFromSolbarnProps {
  enabled: boolean
  filterBy: SolbarnMarketQueryParam.FilterBy
  marketAddress: string
}

const useGetMarketFromSolbarn = ({
  enabled,
  filterBy,
  marketAddress
}: UseGetMarketFromSolbarnProps) => {
  const fetchMarket = useSolbarnGet<SolbarnMarket>(
    `/v1/markets/${marketAddress}?filter_by=${filterBy}`
  )

  return useQuery<SolbarnMarket>({
    enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchMarket(),
    queryKey: ['GetMarketSolana', marketAddress, filterBy]
  })
}

export default useGetMarketFromSolbarn
