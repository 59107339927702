import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Heading,
  Hide,
  HStack,
  Image,
  Link,
  Skeleton,
  Spacer,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { useWallet } from '@solana/wallet-adapter-react'
import AvalancheLogo from 'assets/avalanche.svg'
import SolanaLogo from 'assets/solana.jpg'
import ConnectWalletModal from 'components/ConnectWalletModal'
import { CNATIVE } from 'constants/token'
import useEvmTokenBalance from 'hooks/evm/useTokenBalance'
import React from 'react'
import useSolBalance from 'solana/hooks/useSolBalance'
import { Chain } from 'types/dexbarn'
import { shortenAddress } from 'utils/addresses'
import { getBlockExplorer, getChainId } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { useAccount, useDisconnect } from 'wagmi'

interface WalletAccountProps {
  balance: string | undefined
  chain: Chain
  isBalanceLoading: boolean
  isConnected: boolean
  onConnect: () => void
  onDisconnect: () => void
  chainLogo?: string
  walletAddress?: string
  walletLogo?: string
}

const WalletAccount: React.FC<WalletAccountProps> = ({
  balance,
  chain,
  chainLogo,
  isBalanceLoading,
  isConnected,
  onConnect,
  onDisconnect,
  walletAddress,
  walletLogo
}) => {
  const blockExplorer = getBlockExplorer(walletAddress || '', chain, 'address')
  const chainId = getChainId(chain)
  const nativeCurrency = CNATIVE[chainId]

  let headingText: string
  switch (chain) {
    case 'avalanche':
      headingText = 'EVM:'
      break
    case 'solana':
      headingText = 'Solana:'
      break
  }

  return (
    <HStack align="center" gap={4} w="full">
      {isConnected && walletAddress ? (
        <>
          <Flex flexDir="column" alignItems="flex-start">
            <HStack spacing={2}>
              {walletLogo && (
                <Image src={walletLogo} alt="Chain Logo" boxSize={4} />
              )}
              <Text fontSize="sm" textColor="textSecondary">
                {headingText}
              </Text>
            </HStack>

            <HStack spacing={2}>
              {chainLogo && (
                <Image
                  src={chainLogo}
                  alt="Wallet Logo"
                  boxSize={7}
                  rounded="full"
                />
              )}

              <VStack align="flex-start" spacing={0}>
                <Skeleton isLoaded={!isBalanceLoading}>
                  <Text fontSize="md" fontWeight="bold">
                    {balance ? formattedNum(balance, { places: 5 }) : '0'}{' '}
                    {nativeCurrency.symbol}
                  </Text>
                </Skeleton>
                <Link isExternal href={blockExplorer.url}>
                  <HStack spacing={1}>
                    <Text textColor="textSecondary" fontSize="sm">
                      {shortenAddress(walletAddress)}
                    </Text>
                    <ExternalLinkIcon color="textSecondary" boxSize={3} />
                  </HStack>
                </Link>
              </VStack>
            </HStack>
          </Flex>
          <Spacer />
          <Button size="sm" colorScheme="red" onClick={onDisconnect}>
            Disconnect
          </Button>
        </>
      ) : (
        <>
          <Flex flexDir="column" alignItems="flex-start">
            <Text fontSize="sm" textColor="textSecondary">
              {headingText}
            </Text>
            <Text fontWeight="semibold">Not Connected</Text>
          </Flex>
          <Spacer />
          <Button
            size="sm"
            variant="boxShadowFlat"
            bg="bgSecondary"
            fontSize="sm"
            onClick={onConnect}
          >
            Connect
          </Button>
        </>
      )}
    </HStack>
  )
}

const WalletMenu: React.FC = () => {
  const {
    address: evmAddress,
    connector: activeConnectorEvm,
    isConnected: isEvmConnected
  } = useAccount()
  const { disconnect: disconnectEvm } = useDisconnect()
  const {
    isOpen: isConnectWalletModalOpen,
    onClose: onConnectWalletModalClose,
    onOpen: onConnectWalletModalOpen
  } = useDisclosure()

  const {
    connected: isSolanaConnected,
    disconnect: disconnectSolana,
    publicKey: solanaPublicKey,
    wallet: solanaWallet
  } = useWallet()
  const solanaAddress = solanaPublicKey?.toString()

  // fetch balances
  const { data: evmBalance, isLoading: isEvmBalanceLoading } =
    useEvmTokenBalance({ chain: 'avalanche' })
  const { data: solBalance, isLoading: isSolanaBalanceLoading } =
    useSolBalance()

  const walletAccounts = [
    {
      balance: evmBalance?.formatted,
      chain: 'avalanche' as Chain,
      chainLogo: AvalancheLogo,
      isBalanceLoading: isEvmBalanceLoading,
      isConnected: isEvmConnected,
      onConnect: () => onConnectWalletModalOpen(),
      onDisconnect: disconnectEvm,
      walletAddress: evmAddress,
      walletLogo: activeConnectorEvm?.icon
    },
    {
      balance: solBalance?.formatted,
      chain: 'solana' as Chain,
      chainLogo: SolanaLogo,
      isBalanceLoading: isSolanaBalanceLoading,
      isConnected: isSolanaConnected,
      onConnect: () => onConnectWalletModalOpen(),
      onDisconnect: disconnectSolana,
      walletAddress: solanaAddress,
      walletLogo: solanaWallet?.adapter.icon
    }
  ]

  return (
    <>
      <ConnectWalletModal
        isOpen={isConnectWalletModalOpen}
        onClose={onConnectWalletModalClose}
      />

      <VStack spacing={4} align="stretch" w="full" px={{ base: 0, md: 4 }}>
        <Hide above="md">
          <Heading pt={4} size="sm">
            Wallets:
          </Heading>
        </Hide>
        {walletAccounts.map((account) => (
          <WalletAccount
            key={account.chain}
            chain={account.chain}
            isConnected={account.isConnected}
            walletAddress={account.walletAddress}
            onDisconnect={account.onDisconnect}
            onConnect={account.onConnect}
            walletLogo={account.walletLogo}
            chainLogo={account.chainLogo}
            balance={account.balance}
            isBalanceLoading={account.isBalanceLoading}
          />
        ))}
      </VStack>
    </>
  )
}

export default WalletMenu
