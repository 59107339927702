import { Box, Flex, Heading, Skeleton, Text, VStack } from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import Web3Button from 'components/Web3Button'
import useClaimStakingRewards from 'hooks/tokenmill/useClaimStakingRewards'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { UserStakePosition } from 'types/market'
import { Token } from 'types/token'
import { formattedNum } from 'utils/format'

interface StakeOverviewProps {
  baseToken: Token
  chain: Chain
  isLoadingUserStakePosition: boolean
  marketAddress: string
  onClaimPendingRewardsSuccess: () => void
  quoteToken: Token
  userStakePosition: UserStakePosition | undefined
}

const StakeOverview = ({
  baseToken,
  chain,
  isLoadingUserStakePosition,
  marketAddress,
  onClaimPendingRewardsSuccess,
  quoteToken,
  userStakePosition
}: StakeOverviewProps) => {
  const { claimStakingRewardsAsync, isClaimingStakingRewards } =
    useClaimStakingRewards({
      baseTokenAddress: baseToken.address,
      chain,
      marketAddress,
      quoteTokenAddress: quoteToken.address
    })

  const hasPendingRewards = userStakePosition?.pendingRewards
    ? userStakePosition.pendingRewards.value > 0
    : false

  const handleClaimPendingRewards = async () => {
    await claimStakingRewardsAsync?.()

    onClaimPendingRewardsSuccess()
  }

  return (
    <Box p={{ base: 4, md: 6 }}>
      <VStack align="stretch" spacing={6}>
        <Heading size="sm">OVERVIEW</Heading>

        <VStack align="stretch" spacing={2} fontSize="sm">
          <Flex
            w="full"
            borderBottom="1px dashed"
            borderColor="border"
            justifyContent="space-between"
            pb={1}
          >
            <Text textColor="textSecondary">Staked Balance:</Text>
            <Skeleton isLoaded={!isLoadingUserStakePosition}>
              <Text>
                {`${formattedNum(
                  userStakePosition?.amountStaked?.formatted || '0'
                )} ${baseToken.symbol}`}
              </Text>
            </Skeleton>
          </Flex>

          <Flex
            w="full"
            borderBottom="1px dashed"
            borderColor="border"
            justifyContent="space-between"
            pb={1}
          >
            <TouchFriendlyTooltip
              label={
                chain === 'solana'
                  ? 'Rewards update when you stake or unstake'
                  : ''
              }
            >
              <Text
                textColor="textSecondary"
                textDecor={chain === 'solana' ? 'underline' : 'none'}
                textDecorationStyle="dotted"
              >
                Pending Rewards:
              </Text>
            </TouchFriendlyTooltip>
            <Skeleton isLoaded={!isLoadingUserStakePosition}>
              <Text>
                {`${formattedNum(
                  userStakePosition?.pendingRewards?.formatted || '0'
                )} ${quoteToken.symbol}`}
              </Text>
            </Skeleton>
          </Flex>
        </VStack>

        <Web3Button
          chain={chain}
          size="lg"
          variant="boxShadowFlat"
          width="full"
          bg="accent.500"
          textColor="white"
          isDisabled={!hasPendingRewards || !claimStakingRewardsAsync}
          isLoading={isClaimingStakingRewards}
          loadingText="Claiming"
          onClick={handleClaimPendingRewards}
        >
          [CLAIM REWARDS]
        </Web3Button>
      </VStack>
    </Box>
  )
}

export default StakeOverview
