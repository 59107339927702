import * as spl from '@solana/spl-token'
import { useConnection } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import useGetMarketVestingsFromSolbarn from 'hooks/barn/solana/useGetMarketVestingsFromSolbarn'
import useGetTopStakingPositions from 'hooks/barn/solana/useGetTopStakingPositions'
import { useMemo } from 'react'

interface UseGetTopHoldersProps {
  baseTokenAddress: string
  enabled: boolean
  marketAddress: string
}

const useGetTopHolders = ({
  baseTokenAddress,
  enabled,
  marketAddress
}: UseGetTopHoldersProps) => {
  const { connection } = useConnection()

  const { data: stakingPositions, isLoading: isLoadingStakingPositions } =
    useGetTopStakingPositions({ enabled, marketAddress })

  const { isLoading: isLoadingVestings, vestings } =
    useGetMarketVestingsFromSolbarn({
      enabled,
      marketAddress
    })

  const { data: topHolders, isLoading: isLoadingTopHolders } = useQuery({
    enabled: !!baseTokenAddress && enabled,
    queryFn: async () => {
      const largestAccounts = await connection.getTokenLargestAccounts(
        new PublicKey(baseTokenAddress)
      )

      return largestAccounts.value
    },
    queryKey: ['topHolders', baseTokenAddress]
  })

  const data = useMemo(() => {
    if (!topHolders || !stakingPositions) return []

    const holdersMap = new Map<
      string,
      {
        balance: number
        isMarket: boolean
        staked: number
        total: number
        userAddress: string
        vesting: number
      }
    >()

    const marketBaseToken2022ATA = spl.getAssociatedTokenAddressSync(
      new PublicKey(baseTokenAddress),
      new PublicKey(marketAddress),
      true,
      spl.TOKEN_2022_PROGRAM_ID
    )

    const marketBaseTokenATA = spl.getAssociatedTokenAddressSync(
      new PublicKey(baseTokenAddress),
      new PublicKey(marketAddress),
      true,
      spl.TOKEN_PROGRAM_ID
    )

    // Add token holders
    topHolders.forEach((holder) => {
      holdersMap.set(holder.address.toString(), {
        balance: holder.uiAmount || 0,
        isMarket:
          holder.address.equals(marketBaseTokenATA) ||
          holder.address.equals(marketBaseToken2022ATA),
        staked: 0,
        total: holder.uiAmount || 0,
        userAddress: holder.address.toString(),
        vesting: 0
      })
    })

    // Add/merge staking positions
    stakingPositions.users.forEach((position) => {
      const existing = holdersMap.get(position.user)
      if (existing) {
        existing.staked = position.amount_staked
        existing.total = existing.balance + position.amount_staked
      } else {
        holdersMap.set(position.user, {
          balance: 0,
          isMarket: false,
          staked: position.amount_staked,
          total: position.amount_staked,
          userAddress: position.user,
          vesting: 0
        })
      }
    })

    // Add vesting
    vestings?.forEach((schedule) => {
      const existing = holdersMap.get(schedule.beneficiary)
      const vesting =
        Number(schedule.total.formatted) - Number(schedule.released.formatted)
      if (existing) {
        existing.total = existing.total + vesting
        existing.vesting = vesting
      } else {
        holdersMap.set(schedule.beneficiary, {
          balance: 0,
          isMarket: false,
          staked: 0,
          total: vesting,
          userAddress: schedule.beneficiary,
          vesting
        })
      }
    })

    return Array.from(holdersMap.values())
      .sort((a, b) => b.total - a.total)
      .filter((holder) => holder.total > 0 && !holder.isMarket)
      .slice(0, 10)
  }, [topHolders, stakingPositions, marketAddress, baseTokenAddress, vestings])

  return {
    data,
    isLoading:
      isLoadingStakingPositions || isLoadingTopHolders || isLoadingVestings
  }
}

export default useGetTopHolders
