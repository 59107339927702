import { useInfiniteQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { PortfolioVestingPosition } from 'types/portfolio'
import { SolbarnUserVestingPosition } from 'types/solbarn'

export interface UseGetUserVestingPositionsProps {
  pageSize?: number
  userAddress?: string
}

const useGetUserVestingPositions = ({
  pageSize = 20,
  userAddress
}: UseGetUserVestingPositionsProps = {}) => {
  const fetchUserVestingPositions = useSolbarnGet<SolbarnUserVestingPosition[]>(
    `/v1/vesting/user/${userAddress}`
  )

  return useInfiniteQuery<PortfolioVestingPosition[]>({
    enabled: !!userAddress,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchUserVestingPositions({
        params: {
          page_num: pageParam,
          page_size: pageSize,
          user_id: userAddress
        }
      })

      return data.map(
        (position): PortfolioVestingPosition => ({
          amountReleased: position.amount_released,
          baseTokenLogoUrl: position.icon_url || '',
          baseTokenPriceUsd: position.base_token.price_usd,
          baseTokenSymbol: position.base_token.symbol,
          chain: 'solana',
          cliffDuration: position.cliff_duration,
          marketAddress: position.token_mill_market_id,
          start: new Date(position.created_at).getTime() / 1000,
          valueUsd: position.vesting_amount * position.base_token.price_usd,
          vestingAmount: position.vesting_amount,
          vestingDuration: position.vesting_duration
        })
      )
    },
    queryKey: ['GetUserVestingPositions', pageSize, userAddress]
  })
}

export default useGetUserVestingPositions
