import { TokenMillRouterAbi } from 'constants/abi/tokenMillRouter'
import { TM_ROUTER } from 'constants/addresses'
import { useMemo } from 'react'
import { Chain } from 'types/dexbarn'
import { getChainId } from 'utils/chains'
import {
  BaseError,
  ContractFunctionExecutionError,
  ContractFunctionRevertedError,
  Hex
} from 'viem'
import { useSimulateContract } from 'wagmi'

interface UseRouterSimulateSingleProps {
  chain: Chain
  amount?: bigint
  enabled?: boolean
  packedRoute?: Hex
  value?: bigint
}

const useRouterSimulateSingle = ({
  amount,
  chain,
  enabled,
  packedRoute,
  value
}: UseRouterSimulateSingleProps) => {
  const chainId = getChainId(chain)

  const simulateSingleArgs = useMemo(() => {
    if (!packedRoute || !amount) return undefined
    return [packedRoute, amount, true] as const
  }, [packedRoute, amount])

  const simulateSingleResult = useSimulateContract({
    abi: TokenMillRouterAbi,
    address: TM_ROUTER[chainId],
    args: simulateSingleArgs,
    chainId,
    functionName: 'simulateSingle',
    query: {
      enabled: !!simulateSingleArgs && enabled,
      gcTime: 0
    },
    value
  })

  const amountOut: bigint | undefined = useMemo(() => {
    const simulateSingleError = simulateSingleResult.error
    if (
      simulateSingleError instanceof BaseError ||
      simulateSingleError instanceof ContractFunctionExecutionError
    ) {
      const revertError = simulateSingleError.walk(
        (err) => err instanceof ContractFunctionRevertedError
      )
      if (revertError instanceof ContractFunctionRevertedError) {
        if (revertError.data?.errorName === 'Router__Simulation') {
          const decodedError = revertError.data

          return decodedError?.args?.[0] as bigint | undefined
        }
      }
    }

    return undefined
  }, [simulateSingleResult.error])

  return {
    data: amountOut,
    isLoading: simulateSingleResult.isLoading
  }
}

export default useRouterSimulateSingle
