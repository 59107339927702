import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react'
import React from 'react'

import TransactionSettings from './index'

const TransactionSettingsPopover = (): JSX.Element => {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button
          variant="boxShadowFlat"
          h="40px"
          aria-label="Transaction Settings"
        >
          [Txn Settings]
        </Button>
      </PopoverTrigger>
      <PopoverContent width="fit-content">
        <PopoverBody>
          <TransactionSettings />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default TransactionSettingsPopover
