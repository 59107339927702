import { BN, Idl, Program } from '@coral-xyz/anchor'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useMutation } from '@tanstack/react-query'
import { UseUnstakeTokensProps } from 'hooks/tokenmill/useUnstakeTokens'
import useTransactionToast from 'hooks/useTransactionToast'
import { useCallback } from 'react'
import TokenMillIdl from 'solana/idl/token_mill.json'
import { useFeeMode, useMaxTransactionFee } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { getTokenProgramID } from 'utils/token'
import { buildTransactionWithPriorityFee } from 'utils/transaction'
import { formatUnits } from 'viem'

const useUnstakeTokens = ({
  amount,
  baseTokenAddress,
  baseTokenSymbol,
  chain,
  marketAddress,
  onUnstakeSuccess
}: UseUnstakeTokensProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const addTransactionToast = useTransactionToast()
  const { maxTransactionFee } = useMaxTransactionFee()
  const { feeMode } = useFeeMode()

  const getUnstakeTransaction = useCallback(async () => {
    if (!wallet.publicKey) throw new Error('Wallet not connected')
    if (!amount) throw new Error('Amount not provided')

    const program = new Program(TokenMillIdl as Idl, {
      connection
    })
    const market = new PublicKey(marketAddress)
    const baseTokenMint = new PublicKey(baseTokenAddress)

    const staking = PublicKey.findProgramAddressSync(
      [Buffer.from('market_staking'), market.toBuffer()],
      program.programId
    )[0]

    const stakePosition = PublicKey.findProgramAddressSync(
      [
        Buffer.from('stake_position'),
        market.toBuffer(),
        wallet.publicKey.toBuffer()
      ],
      program.programId
    )[0]

    const baseTokenProgramID = await getTokenProgramID(
      baseTokenAddress,
      connection
    )

    const marketBaseTokenAta = spl.getAssociatedTokenAddressSync(
      baseTokenMint,
      market,
      true,
      baseTokenProgramID
    )

    const userBaseTokenAta = spl.getAssociatedTokenAddressSync(
      baseTokenMint,
      wallet.publicKey,
      true,
      baseTokenProgramID
    )

    const instruction = await program.methods
      .withdraw(new BN(amount))
      .accounts({
        baseTokenMint,
        baseTokenProgram: baseTokenProgramID,
        market,
        marketBaseTokenAta,
        stakePosition,
        staking,
        user: wallet.publicKey,
        userBaseTokenAta
      })
      .instruction()

    return await buildTransactionWithPriorityFee(
      connection,
      [instruction],
      wallet.publicKey,
      maxTransactionFee,
      feeMode
    )
  }, [
    wallet.publicKey,
    marketAddress,
    baseTokenAddress,
    amount,
    connection,
    maxTransactionFee,
    feeMode
  ])

  const {
    isPending: isUnstaking,
    mutateAsync: unstakeAsync,
    reset: resetUnstake
  } = useMutation({
    mutationFn: async () => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')
      if (!amount) throw new Error('Amount not provided')

      const { latestBlockhash, transaction } = await getUnstakeTransaction()

      const signature = await wallet.sendTransaction(transaction, connection)

      addTransactionToast({
        chain,
        description: `Unstaked ${formattedNum(
          formatUnits(amount, 6)
        )} ${baseTokenSymbol}`,
        hash: signature,
        walletAddress: wallet.publicKey.toBase58()
      })

      const result = await connection.confirmTransaction(
        {
          blockhash: latestBlockhash.blockhash,
          lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
          signature
        },
        'processed'
      )

      if (result.value.err) {
        throw new Error('Transaction failed')
      }

      return signature
    },
    onSuccess: onUnstakeSuccess
  })

  return {
    isUnstaking,
    resetUnstake,
    unstakeAsync
  }
}

export default useUnstakeTokens
