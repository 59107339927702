export interface SocialUrls {
  discord: string
  telegram: string
  twitter: string
  website: string
}

export const validateSocialUrl = (
  url: string,
  type: keyof SocialUrls
): string | null => {
  if (!url) return null // Allow empty fields

  const urlPatterns: Record<keyof SocialUrls, RegExp> = {
    discord: /^https?:\/\/(www\.)?discord\.gg\/[a-zA-Z0-9-]+\/?$/,
    telegram:
      /^https?:\/\/(www\.)?t\.me\/(\+[a-zA-Z0-9_-]+|[a-zA-Z0-9_]{5,32})\/?$/,
    twitter: /^https?:\/\/(www\.)?x\.com\/[a-zA-Z0-9_]{1,15}\/?$/,
    website: /^https?:\/\/(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+\/?.*$/
  }

  return urlPatterns[type].test(url) ? null : `Invalid ${type} URL`
}
