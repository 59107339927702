import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import LoginModal from 'components/LoginModal'
import usePatchMarket from 'hooks/barn/usePatchMarket'
import { useS3ImageUpload } from 'hooks/barn/useS3ImageUpload'
import { useCreateTokenLogoUploadUrl } from 'hooks/tokenmill/useCreateTokenLogoUploadUrl'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import useAddSuccessPopup from 'hooks/useAddSuccessPopup'
import CreateTokenSocialUrlInputs from 'pages/CreateToken/CreateTokenSocialUrlInputs'
import ImagePicker from 'pages/CreateToken/ImagePicker'
import React, { useMemo, useState } from 'react'
import { useGetAuthTokens, useIsLoggedIn } from 'state/authentication/hooks'
import { AuthTokens, Chain } from 'types/dexbarn'
import { validateSocialUrl } from 'utils/fields'

interface TokenEditModalProps {
  chain: Chain
  isOpen: boolean
  market: ReturnType<typeof useGetMarket>['data']
  onClose: () => void
}

const TokenEditModal = ({
  chain,
  isOpen,
  market,
  onClose
}: TokenEditModalProps) => {
  const addSuccessPopup = useAddSuccessPopup()

  // edit form state
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [description, setDescription] = useState(market?.description || '')
  const [socialUrls, setSocialUrls] = useState({
    discord: market?.discordUrl || '',
    telegram: market?.telegramUrl || '',
    twitter: market?.twitterUrl || '',
    website: market?.websiteUrl || ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const areSocialUrlsValid = useMemo(() => {
    return (
      validateSocialUrl(socialUrls.discord, 'discord') === null &&
      validateSocialUrl(socialUrls.telegram, 'telegram') === null &&
      validateSocialUrl(socialUrls.twitter, 'twitter') === null &&
      validateSocialUrl(socialUrls.website, 'website') === null
    )
  }, [socialUrls])

  console.log('areSocialUrlsValid', areSocialUrlsValid)

  // login modal
  const {
    isOpen: isLoginModalOpen,
    onClose: onLoginModalClose,
    onOpen: onLoginModalOpen
  } = useDisclosure()

  // token logo upload
  const createTokenLogoUploadUrl = useCreateTokenLogoUploadUrl({
    chain
  })
  const uploadImage = useS3ImageUpload()

  // patch market
  const isLoggedIn = useIsLoggedIn(chain)
  const { tokens: authTokens } = useGetAuthTokens(chain)
  const { mutateAsync: patchMarket } = usePatchMarket({ chain })

  // edit token click handler
  const handleEditToken = async (authTokens: AuthTokens | undefined) => {
    if (!market) return

    if (!isLoggedIn || !authTokens) {
      onLoginModalOpen()
      return
    }

    try {
      setIsLoading(true)

      // upload logo if needed
      let iconUrl = market.baseTokenLogoUrl
      if (selectedImage) {
        const { destinationUrl, fields, presignedUploadUrl } =
          await createTokenLogoUploadUrl({
            authTokens,
            contentType: selectedImage.type
          })
        await uploadImage(presignedUploadUrl, selectedImage, fields)
        iconUrl = destinationUrl
      }

      // PATCH market
      await patchMarket({
        args: {
          description,
          discordUrl: socialUrls.discord,
          iconUrl: iconUrl,
          telegramUrl: socialUrls.telegram,
          twitterUrl: socialUrls.twitter,
          websiteUrl: socialUrls.website
        },
        authTokens,
        chain,
        marketAddress: market.marketAddress
      })

      addSuccessPopup({
        duration: 5000,
        subtitle: 'Token updated',
        summary: 'Your token has been updated'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={onLoginModalClose}
        onLoginSuccess={(tokens) => handleEditToken(tokens)}
        chain={chain}
        type="token"
      />

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Token</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <ImagePicker
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                defaultImageUrl={market?.baseTokenLogoUrl}
              />

              <VStack align="flex-start" w="full">
                <Text textColor="textPrimary" fontSize="sm">
                  Description
                </Text>
                <Textarea
                  placeholder="PUMP IT"
                  value={description}
                  onChange={(e) => setDescription(e.currentTarget.value)}
                />
              </VStack>

              <CreateTokenSocialUrlInputs
                socialUrls={socialUrls}
                setSocialUrls={setSocialUrls}
                isExpandable={false}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="boxShadowFlat">Cancel</Button>
              <Button
                variant="boxShadowFlat"
                bg="accent.500"
                onClick={() => handleEditToken(authTokens)}
                isLoading={isLoading}
                loadingText="Editing token"
                isDisabled={!areSocialUrlsValid}
              >
                Edit Token
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TokenEditModal
