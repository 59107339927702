import { useInfiniteQuery } from '@tanstack/react-query'
import { useDexbarnGet, useSolbarnGet } from 'hooks/useDexbarn'
import { Chain, MarketRecentActivity } from 'types/dexbarn'
import { SolbarnMarketRecentActivity } from 'types/solbarn'

interface UseGetMarketRecentActivityParams {
  address: string
  chain: Chain
  pageSize?: number
}

const useGetMarketRecentActivity = ({
  address,
  chain,
  pageSize = 20
}: UseGetMarketRecentActivityParams) => {
  const fetchRecentActivityEVM = useDexbarnGet<MarketRecentActivity[]>(
    `/v1/markets/${chain}/${address}/recent-activity`
  )

  const fetchRecentActivitySolana = useSolbarnGet<
    SolbarnMarketRecentActivity[]
  >(`/v1/markets/${address}/recent-activity`)

  return useInfiniteQuery<MarketRecentActivity[]>({
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) => {
      switch (chain) {
        case 'avalanche':
          return fetchRecentActivityEVM({
            params: {
              pageNum: pageParam,
              pageSize
            }
          })
        case 'solana':
          return fetchRecentActivitySolana({
            params: {
              page_num: pageParam,
              page_size: pageSize
            }
          }).then((res) =>
            res.map(
              (d) =>
                ({
                  ...d,
                  amountIn: d.amount_in.toString(),
                  amountOut: d.amount_out.toString(),
                  orderType: d.order_type === 'Buy' ? 'bid' : 'ask',
                  tokenIn: {
                    ...d.token_in,
                    priceNative: d.token_in.price_native.toString(),
                    priceUsd: d.token_in.price_usd
                  },
                  tokenOut: {
                    ...d.token_out,
                    priceNative: d.token_out.price_native.toString(),
                    priceUsd: d.token_out.price_usd
                  },
                  tradePrice: d.trade_price,
                  txnHash: d.txn_hash,
                  userAddress: d.user_address
                }) satisfies MarketRecentActivity
            )
          )
      }
    },
    queryKey: ['GetRecentActivity', chain, address, pageSize]
  })
}

export default useGetMarketRecentActivity
