import {
  Button,
  HStack,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useWallet } from '@solana/wallet-adapter-react'
import React, { useState } from 'react'
import { WalletMenuEnum } from 'types/walletMenu'
import { shortenAddress } from 'utils/addresses'
import { useAccount } from 'wagmi'

import ActivityScreen from './ActivityScreen'
import ConnectedScreen from './ConnectedScreen'
import SettingScreen from './SettingScreen'

const WalletMenu = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [walletMenuDisplay, setWalletMenuDisplay] = useState<WalletMenuEnum>(
    WalletMenuEnum.Default
  )

  const evmAccount = useAccount()
  const solanaAccount = useWallet()

  const renderWalletMenu = () => {
    switch (walletMenuDisplay) {
      case WalletMenuEnum.Default:
        return (
          <ConnectedScreen
            isOpen={isOpen}
            setWalletMenuDisplay={setWalletMenuDisplay}
            onClose={onClose}
          />
        )
      case WalletMenuEnum.Settings:
        return (
          <SettingScreen
            onBackClick={() => setWalletMenuDisplay(WalletMenuEnum.Default)}
          />
        )
      case WalletMenuEnum.Activity:
        return <ActivityScreen setWalletMenuDisplay={setWalletMenuDisplay} />
    }
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={() => {
        setWalletMenuDisplay(WalletMenuEnum.Default)
        onOpen()
      }}
      onClose={onClose}
      trigger="hover"
      placement="bottom-end"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button data-cy="wallet-menu-button" variant="boxShadowFlat" h="44px">
          <Text mr={1}>[</Text>
          {evmAccount.isConnecting || evmAccount.isReconnecting ? (
            <Text>Connecting...</Text>
          ) : evmAccount.isConnected && solanaAccount.connected ? (
            <HStack spacing={1}>
              <Image src={evmAccount.connector?.icon} boxSize={4} />
              <Text>{shortenAddress(evmAccount.address || '', 2)}</Text>
              <Text color="textSecondary">+</Text>
              <Image src={solanaAccount.wallet?.adapter.icon} boxSize={4} />
              <Text>
                {shortenAddress(solanaAccount.publicKey?.toString() || '', 2)}
              </Text>
            </HStack>
          ) : evmAccount.isConnected ? (
            <HStack>
              <Image src={evmAccount.connector?.icon} boxSize={4} />
              <Text>{shortenAddress(evmAccount.address || '', 2)}</Text>
            </HStack>
          ) : solanaAccount.connected ? (
            <HStack>
              <Image src={solanaAccount.wallet?.adapter.icon} boxSize={4} />
              <Text>
                {shortenAddress(solanaAccount.publicKey?.toString() || '', 2)}
              </Text>
            </HStack>
          ) : (
            'Connect Wallet'
          )}
          <Text ml={1}>]</Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        py={4}
        minW="450px"
        zIndex={5}
        maxH="80vh"
        overflowY="auto"
      >
        {renderWalletMenu()}
      </PopoverContent>
    </Popover>
  )
}

export default WalletMenu
