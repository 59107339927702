import { BN, Idl, Program } from '@coral-xyz/anchor'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useMutation } from '@tanstack/react-query'
import { UseStakeTokensProps } from 'hooks/tokenmill/useStakeTokens'
import useTransactionToast from 'hooks/useTransactionToast'
import { useCallback } from 'react'
import TokenMillIdl from 'solana/idl/token_mill.json'
import { useFeeMode, useMaxTransactionFee } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { getTokenProgramID } from 'utils/token'
import { buildTransactionWithPriorityFee } from 'utils/transaction'
import { formatUnits } from 'viem'

const useStakeTokens = ({
  amount,
  baseTokenAddress,
  baseTokenSymbol,
  chain,
  marketAddress,
  onStakeSuccess
}: UseStakeTokensProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const addTransactionToast = useTransactionToast()
  const { maxTransactionFee } = useMaxTransactionFee()
  const { feeMode } = useFeeMode()

  const initializeStakingIfNeeded = useCallback(
    async (program: Program, market: PublicKey, staking: PublicKey) => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')

      const stakingAccountInfo = await connection.getAccountInfo(staking)

      if (!stakingAccountInfo) {
        const instruction = await program.methods
          .createStaking()
          .accounts({
            market,
            payer: wallet.publicKey,
            staking
          })
          .instruction()

        return instruction
      }

      return null
    },
    [connection, wallet.publicKey]
  )

  const initializeStakePositionIfNeeded = useCallback(
    async (program: Program, market: PublicKey, stakePosition: PublicKey) => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')

      const stakePositionAccountInfo =
        await connection.getAccountInfo(stakePosition)

      if (!stakePositionAccountInfo) {
        const instruction = await program.methods
          .createStakePosition()
          .accounts({
            market,
            stakePosition,
            user: wallet.publicKey
          })
          .instruction()

        return instruction
      }

      return null
    },
    [connection, wallet.publicKey]
  )

  const getStakeTransaction = useCallback(async () => {
    if (!wallet.publicKey) throw new Error('Wallet not connected')
    if (!amount) throw new Error('Amount not provided')

    const program = new Program(TokenMillIdl as Idl, {
      connection
    })
    const market = new PublicKey(marketAddress)
    const baseTokenMint = new PublicKey(baseTokenAddress)

    const staking = PublicKey.findProgramAddressSync(
      [Buffer.from('market_staking'), market.toBuffer()],
      program.programId
    )[0]

    const stakePosition = PublicKey.findProgramAddressSync(
      [
        Buffer.from('stake_position'),
        market.toBuffer(),
        wallet.publicKey.toBuffer()
      ],
      program.programId
    )[0]

    const baseTokenProgramID = await getTokenProgramID(
      baseTokenAddress,
      connection
    )

    const marketBaseTokenAta = spl.getAssociatedTokenAddressSync(
      baseTokenMint,
      market,
      true,
      baseTokenProgramID
    )

    const userBaseTokenAta = spl.getAssociatedTokenAddressSync(
      baseTokenMint,
      wallet.publicKey,
      true,
      baseTokenProgramID
    )

    const amountBN = new BN(amount)

    const initStakingInstruction = await initializeStakingIfNeeded(
      program,
      market,
      staking
    )
    const initStakePositionInstruction = await initializeStakePositionIfNeeded(
      program,
      market,
      stakePosition
    )

    const stakeInstruction = await program.methods
      .deposit(amountBN)
      .accounts({
        baseTokenMint,
        baseTokenProgram: baseTokenProgramID,
        market,
        marketBaseTokenAta,
        stakePosition,
        staking,
        user: wallet.publicKey,
        userBaseTokenAta
      })
      .instruction()

    const instructions = [
      ...(initStakingInstruction ? [initStakingInstruction] : []),
      ...(initStakePositionInstruction ? [initStakePositionInstruction] : []),
      stakeInstruction
    ]

    return await buildTransactionWithPriorityFee(
      connection,
      instructions,
      wallet.publicKey,
      maxTransactionFee,
      feeMode
    )
  }, [
    wallet.publicKey,
    marketAddress,
    baseTokenAddress,
    amount,
    initializeStakingIfNeeded,
    initializeStakePositionIfNeeded,
    connection,
    maxTransactionFee,
    feeMode
  ])

  const {
    isPending: isStaking,
    mutateAsync: stakeAsync,
    reset: resetStake
  } = useMutation({
    mutationFn: async () => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')
      if (!amount) throw new Error('Amount not provided')

      const { latestBlockhash, transaction } = await getStakeTransaction()

      const signature = await wallet.sendTransaction(transaction, connection)

      addTransactionToast({
        chain,
        description: `Staked ${formattedNum(
          formatUnits(amount, 6)
        )} ${baseTokenSymbol}`,
        hash: signature,
        walletAddress: wallet.publicKey.toBase58()
      })

      const result = await connection.confirmTransaction(
        {
          blockhash: latestBlockhash.blockhash,
          lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
          signature
        },
        'processed'
      )

      if (result.value.err) {
        throw new Error(result.value.err.toString())
      }

      return signature
    },
    onSuccess: onStakeSuccess
  })

  return {
    isStaking,
    resetStake,
    stakeAsync
  }
}

export default useStakeTokens
